import React, { useContext, useEffect, useState } from "react"
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import RadioButtons from "../../componentes/radio";
import descritores from './descritores.json'
import MyContext from "../../servicos/mycontext";

function Nodulo (props) {
    const[tipoDeNodulo,setTipoDeNodulo] = useState(()=> new Map([["N1",["regular","direita","QSL","Quad7"]]]))
    const {setAtualizaSpans} =useContext(MyContext)

    useEffect(() =>{
        geraNodulos()
    },[props.lista])

    const salvarEditar =(nome)=>{
        setAtualizaSpans()
        props.atualizaAchados()
        const estadoAtual = document.getElementById(`divHiddenN_${nome}`).hidden
        const botao = document.getElementById(`but_S_E_${nome}`)
        document.getElementById(`divHiddenN_${nome}`).hidden = !estadoAtual
        estadoAtual? botao.textContent="Salvar":botao.textContent="Editar"
    }
    const selecionaTipo = (nome) =>{
        const tipoAtual = tipoDeNodulo.get(nome)
        const tipo = document.querySelector(`input[name="radio-n-tipo${nome}"]:checked`).value

        const lado = tipoAtual[1]
        const quadrante = tipoAtual[2]
        const hora = tipoAtual[3]
       
        const newObj = new Map(tipoDeNodulo)
        newObj.set(nome,[tipo,lado,quadrante,hora])
        setTipoDeNodulo(newObj)
    }
    const removeNodulo = (element,nodulo) =>{
        if (tipoDeNodulo.size >1) {
            const newMap = new Map(tipoDeNodulo)
            newMap.delete(nodulo)
            setTipoDeNodulo(newMap)
        }
        props.remove(element)
    }
    const selecionaHorarios = (nome,lado,quadrante) =>{
        var result
        switch (lado) {
            case "direita":
                if (quadrante ==="QSL") result = "Quad7"
                if (quadrante ==="QSM") result = "Quad1"
                if (quadrante ==="QIL") result = "Quad5"
                if (quadrante ==="QIM") result = "Quad3"
                if (quadrante ==="JQL") result = "Quad6"
                if (quadrante ==="JQM") result = "Quad2"
                break;
            case "esquerda":
                if (quadrante ==="QSL") result = "Quad1"
                if (quadrante ==="QSM") result = "Quad7"
                if (quadrante ==="QIL") result = "Quad3"
                if (quadrante ==="QIM") result = "Quad5"
                if (quadrante ==="JQL") result = "Quad2"
                if (quadrante ==="JQM") result = "Quad6"
                break;
            default:
                break;
        }
        if (quadrante ==="JQS") result = "Quad8"
        if (quadrante ==="JQI") result = "Quad4"
        const tipo = tipoDeNodulo.get(nome)[0]
        const newObj = new Map(tipoDeNodulo)
        newObj.set(nome,[tipo,lado,quadrante,result])
        setTipoDeNodulo(newObj)
    }
    const criarFrase = (nome) =>{
        var stringFinal=""
        const med1 = parseFloat(document.getElementById(`nod_med_1${nome}`).value).toFixed(1)
        const med2 = parseFloat(document.getElementById(`nod_med_2${nome}`).value).toFixed(1)
        const med3 = parseFloat(document.getElementById(`nod_med_3${nome}`).value).toFixed(1)
        const pele =parseFloat(document.getElementById(`nod_med_pele${nome}`).value).toFixed(1)
        const papila =parseFloat(document.getElementById(`nod_med_papila${nome}`).value).toFixed(1)

        const lado = document.querySelector(`input[name="radio-n-lado${nome}"]:checked`).value
        const quadrante = document.querySelector(`input[name="radio-n-quadrante${nome}"]:checked`).value
        const hora = document.querySelector(`input[name="radio-n-hora${nome}"]:checked`).value
        
        
        var stringPelePapila = ` Distância estimada da pele em ${pele.replace(".",",")} cm e da papila em ${papila.replace(".",",")} cm.`
        if(isNaN(papila)) stringPelePapila = ` Distância estimada da pele em ${pele.replace(".",",")} cm.`
        if(isNaN(pele)) stringPelePapila = ""

        const stringMedidas = med1.replace(".",",")  + " x " + med2.replace(".",",") + " x " + med3.replace(".",",") + " cm"
        
        if(tipoDeNodulo.get(nome)[0]==="regular") {
            stringFinal = `Nódulo regular, hipoecogênico, circunscrito, no ${quadrante} da mama ${lado}, ${hora}, medindo ${stringMedidas}.${stringPelePapila}`
        }
        if(tipoDeNodulo.get(nome)[0]==="ilhota") {
            const ecogenicidade = document.querySelector(`input[name="radio-n-ecogenicidade${nome}"]:checked`).value
            stringFinal = `Nódulo regular, ${ecogenicidade}, circunscrito, no ${quadrante} da mama ${lado}, ${hora}, medindo ${stringMedidas}.${stringPelePapila}
            Pode corresponder a nódulo sólido ou ilhota de gordura`
        }
        
        if(tipoDeNodulo.get(nome)[0]==="complicado") {
            const formato = document.querySelector(`input[name="radio-n-formato${nome}"]:checked`).value
            const efeito = document.querySelector(`input[name="radio-n-efeito${nome}"]:checked`).value
            stringFinal = `Nódulo regular ${formato}, hipoecogênico, circunscrito, ${efeito} no ${quadrante} da mama ${lado}, ${hora}, medindo ${stringMedidas}.${stringPelePapila}
            Pode corresponder a nódulo sólido ou cisto complicado`
        }
        
        if(tipoDeNodulo.get(nome)[0]==="irregular") {
            const ecogenicidade = document.querySelector(`input[name="radio-n-ecogenicidade${nome}"]:checked`).value
            const efeito = document.querySelector(`input[name="radio-n-efeito${nome}"]:checked`).value
            const margem = document.querySelector(`input[name="radio-n-margem${nome}"]:checked`).value
            const orientacao = document.querySelector(`input[name="radio-n-orientacao${nome}"]:checked`).value
            const vascularizacao = document.querySelector(`input[name="radio-n-vascularizacao${nome}"]:checked`).value
            stringFinal = `Nódulo irregular ${ecogenicidade}, ${margem}, ${efeito} e ${orientacao}${vascularizacao}, medindo ${stringMedidas}.${stringPelePapila}`
        }
        if(tipoDeNodulo.get(nome)[0]==="complexo") {
            const circuns = document.querySelector(`input[name="radio-n-circunscrito${nome}"]:checked`).value
            const efeito = document.querySelector(`input[name="radio-n-efeito${nome}"]:checked`).value
            const vascularizacao = document.querySelector(`input[name="radio-n-vascularizacao${nome}"]:checked`).value
            stringFinal = `Nódulo complexo sólido-cístico, ${circuns}, ${efeito}${vascularizacao}, no ${quadrante} da mama ${lado}, ${hora}, medindo ${stringMedidas}.${stringPelePapila}`
        }
        
        document.getElementById(`spanId_${props.sufixo}_${nome}`).innerHTML = stringFinal
        selecionaHorarios(nome,lado,quadrante)
    }
    const geraNodulos =()=>{
        var lista =[]
        const newTipoDeNodulo = new Map(tipoDeNodulo)

        props.lista.forEach(element => {
            const nome = "N"+element
            if(tipoDeNodulo.get(nome) === undefined){
                newTipoDeNodulo.set(nome,["regular","direita","QSL","Quad7"])
                setTipoDeNodulo(newTipoDeNodulo)
            }
            //const horas = descritores.nodulo.horas[tipoDeNodulo.get(nome)[3]]
            const item = <div key={`divN_${nome}`} className='box-modelo'>
                <div id={`divHiddenN_${nome}`}>
                <p>{`Informações de ${nome}`}</p>
                <p className="p-titulo-radios">Tipo</p>
                <RadioButtons opcoes={descritores.nodulo.tipo} name={`radio-n-tipo${nome}`}
                    acao={()=>selecionaTipo(nome)}/>
                <p className="p-titulo-radios">Lado</p>
                <RadioButtons opcoes={descritores.nodulo.lado} name={`radio-n-lado${nome}`}
                    acao={()=>criarFrase(nome)}/>
                <p className="p-titulo-radios">Quadrante</p>
                <RadioButtons opcoes={descritores.nodulo.quadrante} name={`radio-n-quadrante${nome}`}
                    acao={()=>criarFrase(nome)}/>
                <p className="p-titulo-radios">Horas</p>
                {newTipoDeNodulo.get(nome)[3]==="Quad1"?<RadioButtons opcoes={descritores.nodulo.horas.Quad1} name={`radio-n-hora${nome}`}
                    acao={()=>criarFrase(nome)}/>: null}
                {newTipoDeNodulo.get(nome)[3]==="Quad2"?<RadioButtons opcoes={descritores.nodulo.horas.Quad2} name={`radio-n-hora${nome}`}
                    acao={()=>criarFrase(nome)}/>: null}
                {newTipoDeNodulo.get(nome)[3]==="Quad3"?<RadioButtons opcoes={descritores.nodulo.horas.Quad3} name={`radio-n-hora${nome}`}
                    acao={()=>criarFrase(nome)}/>: null}
                {newTipoDeNodulo.get(nome)[3]==="Quad4"?<RadioButtons opcoes={descritores.nodulo.horas.Quad4} name={`radio-n-hora${nome}`}
                    acao={()=>criarFrase(nome)}/>: null}
                {newTipoDeNodulo.get(nome)[3]==="Quad5"?<RadioButtons opcoes={descritores.nodulo.horas.Quad5} name={`radio-n-hora${nome}`}
                    acao={()=>criarFrase(nome)}/>: null}
                {newTipoDeNodulo.get(nome)[3]==="Quad6"?<RadioButtons opcoes={descritores.nodulo.horas.Quad6} name={`radio-n-hora${nome}`}
                    acao={()=>criarFrase(nome)}/>: null}
                {newTipoDeNodulo.get(nome)[3]==="Quad7"?<RadioButtons opcoes={descritores.nodulo.horas.Quad7} name={`radio-n-hora${nome}`}
                    acao={()=>criarFrase(nome)}/>: null}
                {newTipoDeNodulo.get(nome)[3]==="Quad8"?<RadioButtons opcoes={descritores.nodulo.horas.Quad8} name={`radio-n-hora${nome}`}
                    acao={()=>criarFrase(nome)}/>: null}
                
                <p className="p-titulo-radios">Medidas</p>
                    <div>
                        <label className="label-medida">Nódulo mede</label>
                        <input className="input-medida" type="number" id={`nod_med_1${nome}`} onBlur={()=> criarFrase(nome)} ></input> 
                        <label className="label-medida">x</label>
                        <input className="input-medida" type="number" id={`nod_med_2${nome}`} onBlur={()=> criarFrase(nome)} ></input>
                        <label className="label-medida">x</label>
                        <input className="input-medida" type="number" id={`nod_med_3${nome}`} onBlur={()=> criarFrase(nome)}></input>
                        <label className="label-medida">Vol:</label>
                        <input className="input-medida" type="" id={`nod_volume${nome}`} disabled={true}></input>
                        <label className="label-medida">cm³</label>
                    </div>
                <p className="p-titulo-radios">Pele-papila</p>
                    <div>
                        <label className="label-medida">Distância Pele</label>
                        <input className="input-medida" type="number" id={`nod_med_pele${nome}`} onBlur={()=> criarFrase(nome)} ></input> 
                        <label className="label-medida">cm e Papila</label>
                        <input className="input-medida" type="number" id={`nod_med_papila${nome}`} onBlur={()=> criarFrase(nome)} ></input>
                        <label className="label-medida">cm</label>
                        
                    </div>
                {newTipoDeNodulo.get(nome)[0]==="irregular"?<div>
                    <p className="p-titulo-radios">Descrição adicional</p>
                <RadioButtons opcoes={descritores.nodulo.ecogenicidade} name={`radio-n-ecogenicidade${nome}`}
                    acao={()=>criarFrase(nome)}/><p/>
                <RadioButtons opcoes={descritores.nodulo.efeito} name={`radio-n-efeito${nome}`}
                    acao={()=>criarFrase(nome)}/><p/>
                <RadioButtons opcoes={descritores.nodulo.margem} name={`radio-n-margem${nome}`}
                    acao={()=>criarFrase(nome)}/><p/>
                <RadioButtons opcoes={descritores.nodulo.orientacao} name={`radio-n-orientacao${nome}`}
                    acao={()=>criarFrase(nome)}/><p/>
                <RadioButtons opcoes={descritores.nodulo.vascularizacao} name={`radio-n-vascularizacao${nome}`}
                    acao={()=>criarFrase(nome)}/>
                </div>:null}
                {newTipoDeNodulo.get(nome)[0]==="complicado"?<div>
                    <p className="p-titulo-radios">Descrição adicional</p>
                    <RadioButtons opcoes={descritores.nodulo.formato} name={`radio-n-formato${nome}`}
                        acao={()=>criarFrase(nome)}/><p/>
                    <RadioButtons opcoes={descritores.nodulo.efeito} name={`radio-n-efeito${nome}`}
                        acao={()=>criarFrase(nome)}/>
                </div>:null}
                {newTipoDeNodulo.get(nome)[0]==="complexo"?<div>
                    <p className="p-titulo-radios">Descrição adicional</p>
                    <RadioButtons opcoes={descritores.nodulo.circunscrito} name={`radio-n-circunscrito${nome}`}
                        acao={()=>criarFrase(nome)}/><p/>
                    <RadioButtons opcoes={descritores.nodulo.efeito} name={`radio-n-efeito${nome}`}
                        acao={()=>criarFrase(nome)}/>
                    <RadioButtons opcoes={descritores.nodulo.vascularizacao} name={`radio-n-vascularizacao${nome}`}
                        acao={()=>criarFrase(nome)}/>
                </div>:null}
                {newTipoDeNodulo.get(nome)[0]==="ilhota"?<div>
                    <p className="p-titulo-radios">Descrição adicional</p>
                    <RadioButtons opcoes={descritores.nodulo.ecogenicidade} name={`radio-n-ecogenicidade${nome}`}
                        acao={()=>criarFrase(nome)}/><p/>
                </div>:null}
                </div>
                <p className="box-result">
                        <span id = {`spanId_${props.sufixo}_${nome}`}></span> 
                </p>
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                    <Button id={`but_S_E_${nome}`} color="primary" onClick={() =>salvarEditar(nome)}variant="contained">Salvar</Button>
                    <Button color="primary" onClick={()=>removeNodulo(element,nome)} variant="outlined">Remover Nódulo</Button>
                </Stack>
            </div>
            lista.push(item)
        });
        

        return lista
    }

    return (
        <div>
            {geraNodulos()}
        </div>
    )
}

export default Nodulo