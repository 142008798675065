import React from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import './styles.css'

function RadioButtons (props) {

    const opcoes = Object.keys(props.opcoes)

    // objecto padrao a ser enviado:
    // { "op1":[label,value],
    //      "op2":[label,value]
    //      }


    const geraLista = () => {
        var lista = []
        opcoes.forEach((element,index) => {
            const item = <FormControlLabel 
                    key={`key-${index}-${props.name}`}
                    value={props.opcoes[element][1]}
                    control={<Radio />}
                    label={props.opcoes[element][0]}
                    id={element} />
            lista.push(item)
        });

        return lista
    }



    return(
        <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                    name={props.name} defaultValue={props.opcoes.op1[1]}
                    onChange={props.acao}> 
                        {geraLista()}
                 
                </RadioGroup>
        </FormControl>
    )
}
export default RadioButtons