import React, { useEffect, useState } from 'react'
/* import {BotaoSimplesLaranja} from '../../componentes/botoes' */
import './styles.css'
import logo from "../../images/logoEasyRAd.png";
import usuarios from '../../servicos/usuarios.json'
import Button from '@mui/material/Button';
import api from '../../servicos/api';
import LinearProgress from '@mui/material/LinearProgress';




function Login (props) {

    const [mensagem,setMensagem] = useState("")
    const [logado,setLogado] = useState(false)
    const [usuarioLogado,setUsuarioLogado] = useState([])
    const [verBarra,setVerBarra] = useState(true)
    
    useEffect(()=>{
        testaSessao()
    },[])
    
    //entrar sem banco de dados
    const testaLogin = () =>{
        const user= document.getElementById("input1").value
        const senha= document.getElementById("input2").value
        const users = Object.keys(usuarios)
        
        users.forEach (item => {
            if (usuarios[item][0]===user && usuarios[item][2]===senha) {
                props.setUser(usuarios[item])
            } else (
                setMensagem("Erro no login")
            )
        })
    }
    const testaLoginBD = async () =>{
        setVerBarra(true)
        const user= document.getElementById("input1").value
        const senha= document.getElementById("input2").value
        var response
        try {
            response = await api.post('/user/login',{user,senha})
            
        } catch (error) {
            console.log(error)
            
        }
        if (response.data.resposta ==="autorizado"){
            const usuario =[
                response.data.user.username,
                response.data.user.nome,
                "senha",
                response.data.user.crm,


            ]
            console.log("usuario:" + usuario)
            props.setUser(usuario)
        } else {
            setMensagem("Erro no login")
        }
        setVerBarra(false)
    }
    const testaSessao = async () =>{
        var response
        try {
            response= await api.get('./user/testasessao')
        } catch (error) {
            console.log(error)
        }
        if (response.data.resposta==="sucesso") {
            var nomeLogado=response.data.user.nome
            if(nomeLogado.length>20) nomeLogado= nomeLogado.slice(0,17)+"..."
            setMensagem(`Continuar como ${nomeLogado} ?`)
            setLogado(true)
            setUsuarioLogado([
                response.data.user.username,
                response.data.user.nome,
                "senha",
                response.data.user.crm,
            ])
        }
        setVerBarra(false)
    }
    const sair = async ()=>{
        try {
            const response = await api.get('/user/logout')
            if(response){
                setMensagem("Sessão finalizada")
                setLogado(false)
            }
        } catch (error) {
            console.log(error)            
        }
    }
    const entrar = () =>{
        props.setUser(usuarioLogado)
    }

    return (
        <div className='div-sem-css'>
            <div className='login-barra-sup'>
            <p className='barra-sup-texto'>EasyRad</p>

            </div>
            <div className='login-corpo'>
                <div className='login-form'>
                    <img src={logo} alt="EasyRad logo"/>
                    <p className={mensagem.length >0?"p-visivel":"p-invisivel"}>{mensagem}</p>
                    <p className="barra-container">{verBarra?<LinearProgress />:null}</p>
                    
                    {!logado?<input  onSelect={() => setMensagem("")}
                            id= "input1"
                            type="text"
                            placeholder="Usuário"/>:null}
                    {!logado?<input  onSelect={() => setMensagem("")}
                            id="input2"
                            type="password"
                            placeholder="Senha"/>:null}
                    {/* <BotaoSimplesLaranja acao={testaLogin} texto="Entrar"/> */}
                    {/* {!logado?<Button variant="contained"
                     onClick={props.dev?testaLogin:testaLoginBD} color="primary">Entrar</Button>:null} */}
                    {!logado?<Button variant="contained"
                     onClick={testaLoginBD} color="primary">Entrar</Button>:null}
                    {(!logado && props.dev)?<Button variant="contained"
                     onClick={testaLogin} color="primary">Entrar Sem BD</Button>:null}
                    {logado?<div className='login-but-cont'>
                        <Button variant="contained"
                            onClick={entrar} color="primary">Continuar</Button>
                        <Button variant="contained"
                            onClick={sair} color="primary">Sair</Button>
                        </div>:null}
                </div>
            </div>
        </div>
    )
}

export default Login