import React from 'react'
import Button from '@mui/material/Button';
import ExportToDoc from '../../servicos/exportToDoc';

export const laudoView = (param) =>{
    
    const {nomePaciente,titulo,assinatura,crm,dataExame} = param
        const listaFinal = []
        const lista = [<p className="cabecalho" key="dataExame"hidden>{dataExame}</p>]
        if (nomePaciente !== "") {
            lista.push(<p id="cabecalho" key="cabecalho">{`Nome: ${nomePaciente}`}</p>)
        }
        
        lista.push(<h3 className="subtitulo" key="kVt">{titulo}</h3>)
        
            if(param.lista_frases_corpo.length >0){ 
                param.lista_frases_corpo.forEach((element,index) => {
                    if(element)
                    lista.push(<p className="pPadrao"key={`key-frasesL${index}`}>
                        {element}</p>
                    )
                })
            }
            if(param.lista_frases_medidas.length >0){ 
                param.lista_frases_medidas.forEach((element,index) => {
                    if(element)
                    lista.push(<p className="pPadrao"key={`key-frasesM${index}`}>
                        {element}</p>
                    )
                })
            }
            if(param.lista_frases_finais.length >0){ 
                param.lista_frases_finais.forEach((element,index) => {
                    if(element)
                    lista.push(<p className="pPadrao"key={`key-frasesF${index}`}>
                        {element}</p>
                    )
                })
            }
            
            lista.push(<p id="tit-opiniao" className="subtitulo" key="kVo">OPINIÃO</p>)
            
            if(param.lista_frases_opiniao.length >0){ 
                param.lista_frases_opiniao.forEach((element,index) => {
                    if(element)
                    lista.push(<p className="pPadrao"key={`key-frasesO${index}`}>
                        {element}</p>
                    )
                })
            }

            if (assinatura !== undefined) {
                lista.push(<p id="tit-nomemedico" key="nomeM"className={"rodape"}>{assinatura}</p>)
                lista.push(<p id="tit-crm" key="crmM"className={"rodape"}>{crm}</p>)
            }
            listaFinal.push(<div id='previaLaudo' key="previaLaudo">{lista}</div>)
            listaFinal.push(
                <Button key='botaoExport'variant="contained"
                     onClick={() =>ExportToDoc(nomePaciente)}
                     color="primary">Exportar</Button>
            )
        return listaFinal
}