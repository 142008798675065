import React, { useContext, useEffect, useState } from "react";
import descritores from './descritores.json'
import RadioButtons from "../../componentes/radio";
/* import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup' */
import MyContext from "../../servicos/mycontext";

function Cistos(props) {
    const {setAtualizaSpans} =useContext(MyContext)
    const [quantidade,setQuantidade] = useState("unico")
    const [medidas,setMedidas] = useState("um")

    useEffect(()=>{
        criarFrase()
    },[quantidade,medidas])
    
    const selectQuant = () => {
        const quant = document.querySelector(`input[name="radio_cist_quant"]:checked`).value
        setQuantidade(quant)
        if(quant ==="unico") setMedidas("um")
    }
    const selectMedidas = () => {
        const medidas = document.querySelector(`input[name="radio_cist_medidas"]:checked`).value
        setMedidas(medidas)
    }

    const criarFrase = () => {
        var terco, inicio,medidaDir,medidaEsq,med1,med2,med3,medidaString,lado
       
        if (medidas === "um") {
            med1 = parseFloat(document.getElementById(`cist_med_1`).value).toFixed(1)
            med2 = parseFloat(document.getElementById(`cist_med_2`).value).toFixed(1)
            med3 = parseFloat(document.getElementById(`cist_med_3`).value).toFixed(1)
            medidaString = `${med1.replace(".",",")} cm`
            if (!isNaN(med2)) medidaString= `${med1.replace(".",",")} x ${med2.replace(".",",")} cm`
            if (!isNaN(med3)) medidaString= `${med1.replace(".",",")} x ${med2.replace(".",",")} x ${med3.replace(".",",")} cm`
        } else {
            medidaDir = parseFloat(document.getElementById(`cist_med_D`).value).toFixed(1)
            medidaEsq = parseFloat(document.getElementById(`cist_med_E`).value).toFixed(1)
            if (!isNaN(medidaDir)&& !isNaN(medidaEsq)) {
                medidaString = `${medidaDir.replace(".",",")} no lobo direito e ${medidaEsq.replace(".",",")} no lobo esquerdo`
            }

        }
        //const quantidade = document.querySelector(`input[name="radio_cist_quant"]:checked`).value
        //const medidas = document.querySelector(`input[name="radio_cist_medidas"]:checked`).value
        if (document.querySelector(`input[name="radio_cist_lado"]:checked`)) {
            lado = document.querySelector(`input[name="radio_cist_lado"]:checked`).value
        } else {
            lado =""
        }
        if (document.querySelector(`input[name="radio_cist_terco"]:checked`)) {
            terco = document.querySelector(`input[name="radio_cist_terco"]:checked`).value
        }
        
        switch (quantidade) {
            case "raros":
                inicio = "Raros c"
                break;
            case "varios":
                inicio = "Vários c"
                break;
            case "sem":
                inicio = "C"
                break;
        
            default:
                break;
        }

        var unico = `Cisto coloide no ${terco}${lado} medindo ${medidaString}.`
        var raros = `${inicio}istos coloides${lado} medindo até ${medidaString}.`
        var tiradsString = ""
        //var duasMedidas = `${inicio}istos coloides medindo até .`
        if (med1>0.4 || med2>0.4 || med3>0.4 || medidaDir>0.4 || medidaEsq>0.4 )tiradsString=" Classificação TI-RADS - ACR®: 1."

        if (quantidade ==="unico") {
            document.getElementById(`spanId_${props.sufixo}`).innerHTML = unico+tiradsString
        }
        if (quantidade !=="unico"/*  && medidas ==="um" */) {
            document.getElementById(`spanId_${props.sufixo}`).innerHTML = raros+tiradsString
        }
        /* if (medidas ==="maiores") {
            document.getElementById(`spanId_${props.sufixo}`).innerHTML = duasMedidas
        } */
        setAtualizaSpans()
        props.atualizaAchados()
    }

    return(
        <div className="box-modelo">
            <p className="box-modelo-titulo">Cistos coloides</p>
            <p className="p-titulo-radios">Quantidade</p>
            <RadioButtons opcoes={descritores.cistos.quantidade} name={`radio_cist_quant`}
                acao={selectQuant}/>
            {quantidade !=="unico"?
                <p className="p-titulo-radios">Medidas de..</p>:null}
            {quantidade !=="unico"?
                <RadioButtons opcoes={descritores.cistos.medidas} name={`radio_cist_medidas`}
                    acao={selectMedidas}/>:null}
            {medidas !=="maiores"?<p className="p-titulo-radios">Localização</p>:null}
            {(quantidade === "unico" && medidas !== "maiores")?
                <RadioButtons opcoes={descritores.cistos.ladoUnico} name={`radio_cist_lado`}
                    acao={criarFrase}/>:null}
            {(quantidade !== "unico" && medidas !== "maiores")?
                <RadioButtons opcoes={descritores.cistos.ladoVarios} name={`radio_cist_lado`}
                    acao={criarFrase}/>:null}
            {quantidade === "unico"?
                <p className="p-titulo-radios">Em terço...</p>:null}
            {quantidade === "unico"?
                <RadioButtons opcoes={descritores.cistos.terco} name={`radio_cist_terco`}
                    acao={criarFrase}/>:null}
            <p className="p-titulo-radios">Medidas</p>
            {medidas!=="maiores"?<div>
                <label className="label-medida">Maior cisto mede</label>
                <input className="input-medida" type="number" id={`cist_med_1`} onBlur={()=> criarFrase()} ></input>
                <label className="label-medida">x</label>
                <input className="input-medida" type="number" id={`cist_med_2`} onBlur={()=> criarFrase()} ></input>
                <label className="label-medida">x</label>
                <input className="input-medida" type="number" id={`cist_med_3`} onBlur={()=> criarFrase()}></input>
                <label className="label-medida">cm</label>
            </div>:null}
            {medidas ==="maiores"?<div>
                <label className="label-medida">cisto lobo direito mede</label>
                <input className="input-medida" type="number" id={`cist_med_D`} onBlur={()=> criarFrase()} ></input>
                <label className="label-medida">cm</label>
            </div>:null}
            {medidas ==="maiores"?<div>
                <label className="label-medida">cisto lobo esquerdo mede</label>
                <input className="input-medida" type="number" id={`cist_med_E`} onBlur={()=> criarFrase()} ></input>
                <label className="label-medida">cm</label>
            </div>:null}
            <p className="box-result" /* onClick ={() => setMostraEscondeModal(true)} */>
                <span id = {`spanId_${props.sufixo}`} /* title={props.modulo.nome} */ ></span>
            </p>
        </div>
    )
}
export default Cistos