import React, { useContext, useEffect } from "react"
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import RadioButtons from "../../componentes/radio";
import descritores from './descritores.json'
import MyContext from "../../servicos/mycontext";

function Nodulo (props) {

    //const[showDiv,setShowDiv] =useState(true)
    const {setAtualizaSpans} =useContext(MyContext)
    /* const [listaNodulos,setListaNodulos] = useState([]) */
    /* const [noduloAtivo,setNoduloAtivo] = useState("") */
    useEffect(() =>{
        geraNodulos()
    },[props.lista])

    

    const salvarEditar = (nome) => {
        setAtualizaSpans()
        props.atualizaAchados()
      //  setShowDiv(!showDiv)
        const estadoAtual = document.getElementById(`divHiddenN_${nome}`).hidden
        const botao = document.getElementById(`but_S_E_${nome}`)
        document.getElementById(`divHiddenN_${nome}`).hidden = !estadoAtual
        estadoAtual? botao.textContent="Salvar":botao.textContent="Editar"
    }

    const criarFrase = (nome) =>{
        const med1 = parseFloat(document.getElementById(`nod_med_1${nome}`).value).toFixed(1)
        const med2 = parseFloat(document.getElementById(`nod_med_2${nome}`).value).toFixed(1)
        const med3 = parseFloat(document.getElementById(`nod_med_3${nome}`).value).toFixed(1)
        
        const lado = document.querySelector(`input[name="radio-n-l${nome}"]:checked`).value.slice(1)
        const terco = document.querySelector(`input[name="radio-n-t${nome}"]:checked`).value.slice(1)
        const composicao = document.querySelector(`input[name="radio-n-c${nome}"]:checked`).value.slice(1)
        const composicao_T =  parseInt(document.querySelector(`input[name="radio-n-c${nome}"]:checked`).value[0])
        const ecogenicidade = document.querySelector(`input[name="radio-n-e${nome}"]:checked`).value.slice(1)
        const ecogenicidade_T =  parseInt(document.querySelector(`input[name="radio-n-e${nome}"]:checked`).value[0])
        const contornos = document.querySelector(`input[name="radio-n-cont${nome}"]:checked`).value.slice(1)
        const contornos_T =  parseInt(document.querySelector(`input[name="radio-n-cont${nome}"]:checked`).value[0])
        const orientacao = document.querySelector(`input[name="radio-n-o${nome}"]:checked`).value.slice(1)
        const orientacao_T =  parseInt(document.querySelector(`input[name="radio-n-o${nome}"]:checked`).value[0])
        const focos = document.querySelector(`input[name="radio-n-f${nome}"]:checked`).value.slice(1)
        const focos_T =  parseInt(document.querySelector(`input[name="radio-n-f${nome}"]:checked`).value[0])
        const doppler = document.querySelector(`input[name="radio-n-d${nome}"]:checked`).value.slice(1)
        const valor= orientacao_T+composicao_T+ecogenicidade_T+contornos_T+focos_T
        var tirads = ""
        switch (valor) {
            case 0:
                tirads = 1;
                break;
            case 1:
                tirads = 1;
                break;
            case 2:
                tirads = 2;
                break;
            case 3:
                tirads = 3;
                break;
            case 4:
                tirads = 4;
                break;
            case 5:
                tirads = 4;
                break;
            case 6:
                tirads = 4;
                break;
            default:
                tirads = 5
        }
        var tiradsString = " Classificação TI-RADS - ACR®: " + tirads + "."
        if (med1<0.5 && med2<0.5 && med3 < 0.5) tiradsString= ""
        const string = "Nódulo " + composicao + ", " + ecogenicidade + ", " + contornos + orientacao +  
                        focos + ", no " + terco + lado + " (" + nome.toUpperCase() + "), de " +
                        med1.replace(".",",")  + " x " + med2.replace(".",",") + " x " + med3.replace(".",",") + " cm, " + doppler +
                         " ao estudo Doppler." + tiradsString
        
        document.getElementById(`nod_volume${nome}`).value = (med1*med2*med3*0.523).toFixed(2)
        document.getElementById(`spanId_9c_${nome}`).innerHTML = string


    }

    const geraNodulos = () => {
        var lista =[]
        const nodulosArray = Object.keys(props.lista)
        nodulosArray.forEach((nome)=>{
            const item =
                <div key={`divN${nome}`}className='box-modelo'>
                    <div id={`divHiddenN_${nome}`}>
                    <p className="box-modelo-titulo">{`Nódulo ${props.lista[nome][0]} `}</p>
                    <p className="p-titulo-radios">Lado</p>
                    <RadioButtons opcoes={descritores.nodulo.lado} name={`radio-n-l${nome}`}
                        acao={() => criarFrase(nome)}/>
                     <p className="p-titulo-radios">Terço</p>
                    <RadioButtons opcoes={descritores.nodulo.terco} name={`radio-n-t${nome}`}
                        acao={() => criarFrase(nome)}/>
                    <p className="p-titulo-radios">Composição</p>
                    <RadioButtons opcoes={descritores.nodulo.composicao} name={`radio-n-c${nome}`}
                        acao={() => criarFrase(nome)}/>
                   <p className="p-titulo-radios">Ecogenicidade</p>
                    <RadioButtons opcoes={descritores.nodulo.ecogenicidade} name={`radio-n-e${nome}`}
                        acao={() => criarFrase(nome)}/>
                    <p className="p-titulo-radios">Contornos</p>
                    <RadioButtons opcoes={descritores.nodulo.contornos} name={`radio-n-cont${nome}`}
                        acao={() => criarFrase(nome)}/>
                    <p className="p-titulo-radios">Orientação</p>
                    <RadioButtons opcoes={descritores.nodulo.orientacao} name={`radio-n-o${nome}`}
                        acao={() => criarFrase(nome)}/>
                    <p className="p-titulo-radios">Focos Ecogênicos</p>
                    <RadioButtons opcoes={descritores.nodulo.focos} name={`radio-n-f${nome}`}
                        acao={() => criarFrase(nome)}/>
                    <p className="p-titulo-radios">Doppler</p>
                    <RadioButtons opcoes={descritores.nodulo.doppler} name={`radio-n-d${nome}`}
                        acao={() => criarFrase(nome)}/>
                    <p className="p-titulo-radios">Medidas</p>
                    <div>
                        <label className="label-medida">Nódulo mede</label>
                        <input className="input-medida" type="number" id={`nod_med_1${nome}`} onBlur={()=> criarFrase(nome)} ></input> 
                        <label className="label-medida">x</label>
                        <input className="input-medida" type="number" id={`nod_med_2${nome}`} onBlur={()=> criarFrase(nome)} ></input>
                        <label className="label-medida">x</label>
                        <input className="input-medida" type="number" id={`nod_med_3${nome}`} onBlur={()=> criarFrase(nome)}></input>
                        <label className="label-medida">Vol:</label>
                        <input className="input-medida" type="" id={`nod_volume${nome}`} disabled={true}></input>
                        <label className="label-medida">cm³</label>
                    </div>
                    </div>
                     <p className="box-result" /* onClick ={() => setMostraEscondeModal(true)} */>
                        <span id = {`spanId_${props.sufixo}_${nome}`} /* title={props.modulo.nome} */ ></span> 
                    </p>

                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <Button id={`but_S_E_${nome}`} color="primary" onClick={() =>salvarEditar(nome)}variant="contained">Salvar</Button>
                        <Button color="primary" onClick={()=>props.remove(nome)} variant="outlined">Remover Nódulo</Button>
                    </Stack>
                </div>
            lista.push(item)
        })
        return lista

    }
    return(
        <div>
            {geraNodulos()}
        </div>
    )
}
export default Nodulo