import React, { useContext, useEffect } from "react";
import MyContext from "../../servicos/mycontext";

function Opiniao(props) {

    const { setAtualizaSpans } = useContext(MyContext)
   
    useEffect(()=>{
        geraOpiniao()
    },[props.achados])

    const geraOpiniao = () =>{
        //exemplo de props.achados
        // noduloD:0,noduloE:0,noduloI:0,cisto:0,tirads:0,frase1:0
        const valores = Object.values(props.achados)
        const nomes = Object.keys(props.achados)
        const valor = valores.reduce((acc,cur)=>acc+cur,0)
                
        var quantNodulo = 0
        for (let index = 0; index < 3; index++) {
            quantNodulo=valores[index]+quantNodulo
        }
        if (props.achados.tirads > 0){
            document.getElementById("4o").value = `${props.achados.tirads+1}`
            onSelect("4o") 
        } else {
            document.getElementById("4o").value = "1"
            onSelect("4o") 

        }
        if (valor=== 0) {
            document.getElementById("1o").value = "1"
            document.getElementById("2o").value = "1"
            document.getElementById("4o").value = "1"
            onSelect("1o")
            onSelect("2o")
            onSelect("4o")
            
            //normal
        }
        if (valor === 1 && props.achados.cisto >0) {
            document.getElementById("1o").value = "2"
            document.getElementById("2o").value = "1"
            document.getElementById("4o").value = "1"
            onSelect("1o")
            onSelect("2o")
            onSelect("4o")
            //Ausencia de altera.......
            //console.log(valores.indexOf(1))
        }
        if (quantNodulo>0 && props.achados.frase1 ===0) {
            //tem nódulo - retira frase normal e insere observação
            const selecionado = document.getElementById("1o");
            selecionado.value = "11"
            const texto = selecionado.options[selecionado.selectedIndex].text;
            document.getElementById(`spanId_1o`).innerHTML = texto;
            document.getElementById("6o").value = "2"
            onSelect("6o") 
        }
        if (quantNodulo===1) {
            //tem somente um nódulo - define opinião baseada na lateralidade
            const lado = nomes[valores.indexOf(1)]
            const selecionado = document.getElementById("2o");
            switch (lado) {
                case "noduloD":
                selecionado.value = "2"
                    break;
                case "noduloE":
                selecionado.value = "4"
                    break;
                case "noduloI":
                selecionado.value = "6"
                    break;
                default:
                    break;
            }
            const texto = selecionado.options[selecionado.selectedIndex].text;
            document.getElementById(`spanId_2o`).innerHTML = texto;
            setAtualizaSpans()
        }
        if (quantNodulo > 1) {
            const selecionado = document.getElementById("2o")
            if (quantNodulo === props.achados.noduloD) {
                selecionado.value = "3"
            } else if(quantNodulo === props.achados.noduloE){
                selecionado.value = "5"
            } else if (quantNodulo === props.achados.noduloI){
                selecionado.value = "7"
            } else { selecionado.value = "8"}
            onSelect("2o")
        }
    }

    const renderLista = (param,id) => {
        const sufixo= id+props.sufixo
        var spanId = "spanId_" + sufixo
        const keys = Object.keys(param)
        var lista = []
        var padrao
        keys.forEach((key, index) => {
            key === "1" ? padrao = true : padrao = false
            const element =
                <option
                    key={`key-option${index}`}
                    value={key}
                    defaultChecked={padrao}
                >{param[key]}</option>
            if (key !== "nome") lista.push(element)
        })
        return <div className='box-modelo'>
            <p className="box-modelo-titulo">{`Opiniao - ${param.nome}`}</p>
            <div className="div-select">
                <select className="select-laudo-box" id={sufixo} onChange={() => onSelect(sufixo)}>
                    {lista}
                </select>
            </div>
            <p className="box-result">
                <span id={spanId} title={props.modulo.nome} >{param["1"]}</span>
            </p>
       </div>

    }

    const onSelect = (sufixo) => {
        const selecionado = document.getElementById(sufixo);
        const texto = selecionado.options[selecionado.selectedIndex].text;
        document.getElementById(`spanId_${sufixo}`).innerHTML = texto;
        setAtualizaSpans()
    }
    return (<div>
        {renderLista(props.modulo.paragrafo1,"1")}
        {renderLista(props.modulo.paragrafo2,"2")}
        {renderLista(props.modulo.paragrafo3,"3")}
        {renderLista(props.modulo.paragrafo4,"4")}
        {renderLista(props.modulo.paragrafo5,"5")}
        {renderLista(props.modulo.paragrafo6,"6")}
    </div>)
}
export default Opiniao