import './styles.css'
import Cabecalho from '../../componentes/boxes/cabecalho'
import Titulo from '../../componentes/boxes/titulo'
import MyContext from '../../servicos/mycontext'
import React, { useContext, useEffect, useState } from 'react'
import descritores from './descritores.json'
import {laudoView} from "./laudoView"
import Select from '../../componentes/boxes/select'
import Nodulo from './nodulo'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
//import Opiniao from './opiniao'

function Mamas (props) {
    const {atualizaSpans,setLaudoView,user} = useContext(MyContext)
    const [verDoppler,setVerDoppler]=useState(false)
    const [nodulos, setNodulos] = useState(() => new Set());
    const [atualizaAchados,setAtualizaAchados] = useState(true)

    
    useEffect(() =>{
        geraLaudoView()
    },[atualizaSpans,nodulos])
    
    const triggerAchados = () =>{
        setAtualizaAchados(!atualizaAchados)
    }
    
    const adicionaNodulo = () => {
        const lista = new Set()
        for (let index = 1; index < nodulos.size+2; index++) {
            console.log("no loop")
                lista.add(index)
        }
        console.log("no loop 1")
        console.log(lista)
        setNodulos(lista)
        /* const quantidadeDeNodulos= Object.keys(nodulos).length
        const chave = `n${quantidadeDeNodulos+1}`
        setNodulos({...nodulos,[chave]:[quantidadeDeNodulos+1]})     */
    }
    const removeNodulo = (i) => {
        var lista = new Set(nodulos)
        lista.delete(i)
        setNodulos(lista)
    }
    const geraLaudoView = () =>{
        var currentdate = new Date();
        const dataExame = "Data do exame: " + currentdate.getDate() + "/" +
                        (currentdate.getMonth()+1) + "/" +
                        currentdate.getFullYear()
        const titulo = document.querySelector('input[name="titulo"]:checked').nextElementSibling.textContent
        var nomePaciente, assinatura, crm
        if(document.getElementById("nome-paciente")) {
            nomePaciente =document.getElementById("nome-paciente").value
        } else {
            nomePaciente = ""
        }
        if (document.getElementById("assin-sim").checked) {
            assinatura = user[1]
            crm = user[3]
        }
        var lista_frases_corpo = []
        //var lista_frases_medidas = []
        var lista_frases_opiniao = []
        var lista_frases_finais = []
        const spans = document.querySelectorAll("span")
        spans.forEach((span)=>{
            /* console.log(span.id.slice(8,9)) */
            if(span.id.slice(8,9)==="c") lista_frases_corpo.push(span.innerHTML)
            if(span.id.slice(8)==="o") lista_frases_opiniao.push(span.innerHTML)
            //if(span.id.slice(8)==="m") lista_frases_medidas.push(span.innerHTML)
            if(span.id.slice(8,9)==="f") lista_frases_finais.push(span.innerHTML)
        })
        
        setLaudoView(laudoView({
            dataExame,
            titulo,
            nomePaciente,
            assinatura,
            crm,
            lista_frases_corpo,
            //lista_frases_medidas,
            lista_frases_opiniao,
            lista_frases_finais
        }))
        /* console.log(lista_frases_opiniao) */
    }

    const ativaDoppler =(item)=>{
        if (item ===1 || item ===3){
            setVerDoppler(true)
        } else {setVerDoppler(false)}
    }
    return (
    <div>
        <Cabecalho/>
        <Titulo descritores={descritores} acao={ativaDoppler}/> 
        <Select sufixo="1c" modulo = {descritores.paragrafo1}/>
        <Select sufixo="2c" modulo = {descritores.paragrafo2}/>
        <Select sufixo="3c" modulo = {descritores.paragrafo3}/>
        <Nodulo lista={nodulos} sufixo="4c"remove={removeNodulo} atualizaAchados={triggerAchados}/>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <Button variant="contained" size="small" onClick={() =>adicionaNodulo()}
                         color="primary">Nódulo +</Button>
                    {/* <Button variant="contained" size="small" onClick={() =>setCistos(!cistos)}
                         color="primary">Cisto +</Button>
                    <Button variant= "contained" size="small" onClick={() =>setGsalivares(!gsalivares)}
                        color={!gsalivares?"primary":"secondary"}>
                        {!gsalivares?"G.Salivares +":"G.Salivares -"}</Button> */}
                </Stack>
    </div>
    )
}
export default Mamas 