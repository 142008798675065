import './styles.css'
import Cabecalho from '../../componentes/boxes/cabecalho'
import Titulo from '../../componentes/boxes/titulo'
import MyContext from '../../servicos/mycontext'
import React, { useContext, useEffect, useState } from 'react'
import descritores from './descritores.json'
import {laudoView} from "./laudoView"
import Select from '../../componentes/boxes/select'
import Medidas3Vol from '../../componentes/boxes/medidas3vol'
import VGT from './vgt'
import Nodulo from './nodulo'
import Salivares from './salivares'
import Cistos from './cistos'
import Linfonodos from './linfonodos'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Opiniao from './opiniao'

/* import Stack from '@mui/material/Stack'; */



function Tiroide (props) {
    
    const {atualizaSpans,setLaudoView,user} = useContext(MyContext)
    const [volD,setVolD] =useState("")
    const [volE,setVolE] =useState("")
    const [volI,setVolI] =useState("")
    const [nodulos,setNodulos] = useState({})
    const [gsalivares,setGsalivares]=useState(false)
    const [cistos,setCistos]=useState(false)
    const [verDoppler,setVerDoppler]=useState(false)
    const [atualizaAchados,setAtualizaAchados] = useState(true)
    const [achados,setAchados] = useState({noduloD:0,noduloE:0,noduloI:0,cisto:0,tirads:0,frase1:0})
    
    useEffect(() =>{
    
        geraLaudoView()
    },[atualizaSpans,gsalivares,nodulos])
    
    useEffect(() =>{
        checaNumeroNodulos();
    
    },[atualizaAchados,nodulos,cistos])
    
    const triggerAchados = () =>{
        setAtualizaAchados(!atualizaAchados)
    }
    
    const checaNumeroNodulos = () => {
        var nodulos = {direito:0,esquerdo:0,istmo:0,cisto:0}
        var tirads = 0
        const spans = document.querySelectorAll("[id^='spanId_9c']")
        spans.forEach(e=>{
            const texto = e.innerHTML
            if (texto.search("direito") !== -1) nodulos.direito = nodulos.direito+1
            if (texto.search("esquerdo") !== -1) nodulos.esquerdo = nodulos.esquerdo+1
            if (texto.search("istmo") !== -1) nodulos.istmo = nodulos.istmo+1
            
            const acr = texto.indexOf("ACR")
            const currTirads = parseInt(texto.slice(acr+6,acr+7))
            if (currTirads > tirads) tirads =currTirads
        })
        if (document.getElementById("spanId_4c")) {
            const textoCisto = document.getElementById("spanId_4c").innerHTML
            
            const acr = textoCisto.indexOf("ACR")
            const currTirads = parseInt(textoCisto.slice(acr+6,acr+7))
            if (currTirads > tirads) tirads =currTirads
            
            if (textoCisto.search("coloide") !== -1) nodulos.cisto = 1
            if (textoCisto.search("coloides") !== -1) nodulos.cisto = 2
        }
        
        const newAchados = {...achados,
            noduloD:nodulos.direito,
            noduloE:nodulos.esquerdo,
            noduloI:nodulos.istmo,
            cisto:nodulos.cisto,
            tirads
        }
        mudaFraseNoduloCisto(newAchados)
        setAchados(newAchados)
    }
    const mudaFraseNoduloCisto = (newAchados) => {
        const valores = Object.values(newAchados)
        var quantNodulo = 0
        const selecionado = document.getElementById("3c")

        for (let index = 0; index < 3; index++) {
            quantNodulo=valores[index]+quantNodulo
        }
        if (quantNodulo > 0 &&  newAchados.cisto > 0) {
            selecionado.value = "4"
        }
        if (quantNodulo > 0 &&  newAchados.cisto === 0) {
            selecionado.value = "3"
        }
        if (quantNodulo === 0 &&  newAchados.cisto > 0) {
            selecionado.value = "2"
        }
        if (quantNodulo === 0 &&  newAchados.cisto === 0) {
        selecionado.value = "1"
        }
        const texto = selecionado.options[selecionado.selectedIndex].text;
        document.getElementById("spanId_3c").innerHTML = texto;

    }
    const adicionaNodulo = (i) => {
        const quantidadeDeNodulos= Object.keys(nodulos).length
        const chave = `n${quantidadeDeNodulos+1}`
        setNodulos({...nodulos,[chave]:[quantidadeDeNodulos+1]})    
    }
    
    const removeNodulo = (i) => {
        var newObj = {...nodulos}
        delete newObj[i]
        setNodulos(newObj)
    }

    const geraLaudoView = () =>{
        var currentdate = new Date();
        const dataExame = "Data do exame: " + currentdate.getDate() + "/" +
                        (currentdate.getMonth()+1) + "/" +
                        currentdate.getFullYear()
        const titulo = document.querySelector('input[name="titulo"]:checked').nextElementSibling.textContent
        var nomePaciente, assinatura, crm
        if(document.getElementById("nome-paciente")) {
            nomePaciente =document.getElementById("nome-paciente").value
        } else {
            nomePaciente = ""
        }
        if (document.getElementById("assin-sim").checked) {
            assinatura = user[1]
            crm = user[3]
        }
        var lista_frases_corpo = []
        var lista_frases_medidas = []
        var lista_frases_opiniao = []
        var lista_frases_finais = []
        const spans = document.querySelectorAll("span")
        spans.forEach((span)=>{
            /* console.log(span.id.slice(8,9)) */
            if(span.id.slice(8,9)==="c") lista_frases_corpo.push(span.innerHTML)
            if(span.id.slice(8)==="o") lista_frases_opiniao.push(span.innerHTML)
            if(span.id.slice(8)==="m") lista_frases_medidas.push(span.innerHTML)
            if(span.id.slice(8,9)==="f") lista_frases_finais.push(span.innerHTML)
        })
        
        setLaudoView(laudoView({
            dataExame,
            titulo,
            nomePaciente,
            assinatura,
            crm,
            lista_frases_corpo,
            lista_frases_medidas,
            lista_frases_opiniao,
            lista_frases_finais
        }))
        /* console.log(lista_frases_opiniao) */
    }
    const ativaDoppler =(item)=>{
        if (item ===1 || item ===3){
            setVerDoppler(true)
        } else {setVerDoppler(false)}
    }

    return (
        <div>
            <Cabecalho/>
            <Titulo descritores={descritores} acao={ativaDoppler}/>
            <Select sufixo="1c" modulo = {descritores.paragrafo1}/>
            <Select sufixo="2c" modulo = {descritores.paragrafo2}/>
            <Select sufixo="3c" modulo = {descritores.paragrafo3}/>
            {verDoppler && <Select sufixo="5c" modulo = {descritores.paragrafo4}/>}
            <Medidas3Vol acao={setVolD} titulo="Medidas Lobo Direito" texto="Lobo tiroidiano direito: "sufixo="1m"/>
            <Medidas3Vol acao={setVolE}titulo="Medidas Lobo Esquerdo" texto="Lobo tiroidiano esquerdo: "sufixo="2m"/>
            <Medidas3Vol acao={setVolI}titulo="Medidas Istmo" texto="Istmo: "sufixo="3m"/>
            <VGT volD={volD}volE={volE}volI={volI} sufixo="4m"/>
            <Nodulo lista={nodulos} sufixo="9c"remove={removeNodulo} atualizaAchados={triggerAchados}/>
            <div className='box-modelo'>
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <Button variant="contained" size="small" onClick={() =>adicionaNodulo()}
                         color="primary">Nódulo +</Button>
                    <Button variant="contained" size="small" onClick={() =>setCistos(!cistos)}
                         color="primary">Cisto +</Button>
                    <Button variant= "contained" size="small" onClick={() =>setGsalivares(!gsalivares)}
                        color={!gsalivares?"primary":"secondary"}>
                        {!gsalivares?"G.Salivares +":"G.Salivares -"}</Button>
                </Stack>
                
            </div>
            {gsalivares && <Salivares sufixo ="1f" sufixo2="2f"/>}
            {cistos && <Cistos sufixo ="4c" atualizaAchados={triggerAchados}/>}
            <Linfonodos sufixo="3f" modulo = {descritores.paragrafo5}/>
            <Opiniao sufixo="o" modulo={descritores.opiniao} achados={achados}/>
        </div>
    )
}
export default Tiroide