import React, { useEffect, useContext,useState } from "react";
/* import Chip from '@mui/material/Chip'; */
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import RadioButtons from "../../componentes/radio";
import descritores from './descritores.json'
import MyContext from "../../servicos/mycontext";

function Linfonodos(props) {
    const {setAtualizaSpans} =useContext(MyContext)
    const [buttonVariant, setButtonVariant] = useState({
        "L1B1": "contained", // normais
        "L1B2": "outlined",  // tipico
        "L1B3": "outlined",  // atipico
        "L1B4": "outlined",  // tipicos
        "L1B5": "outlined",  // atipicos
        "L2B1": "contained", //tipico direita
        "L2B2": "outlined",  // tipico esquerda
        "L2B3": "outlined",  //tipico bilateral
    })
    const [lado, setLado] = useState("direita")

    useEffect(() => {
        if (buttonVariant.L1B1 === "contained") setStrintToSpan("Não há sinais de linfonodomegalias cervicais.")
        /* if(buttonVariant.L1B2==="contained" && (buttonVariant.L2B1==="contained" || buttonVariant.L2B2==="contained")) getDataUnicoTipico() */
    }, [buttonVariant])

    const mudarBotoes = (id) => {
        let newArray = { ...buttonVariant }
        const lista = Object.keys(newArray)
        lista.forEach((item) => {
            if (item.slice(0, 2) === id.slice(0, 2))
                item === id ? newArray[item] = "contained" :
                    newArray[item] = "outlined"
        })
        setButtonVariant(newArray)
    }
    const setStrintToSpan = (string) => {
        document.getElementById(`spanId_${props.sufixo}`).innerHTML = string
        setAtualizaSpans()
    }
    const getDataUnicoTipico = () => {
        const selecionado = document.getElementById(`select-linf-unilateral`);
        const nivel = selecionado.options[selecionado.selectedIndex].id;
        const medida = document.getElementById("unica").value
        const string = "Linfonodo proeminente com morfologia e textura habituais, em nível " +
            nivel + " à " + lado + ", mede " + medida + " cm."
        setStrintToSpan(string)
    }
    const getDataUnicoAtipico = () => {
        const hilo = document.querySelector(`input[name="radioLHilo"]:checked`).value
        const textura = document.querySelector(`input[name="radioLText"]:checked`).value
        const calcif = document.querySelector(`input[name="radioLCalc"]:checked`).value
        const selecionado = document.getElementById(`select-linf-unilateral`);
        const nivel = selecionado.options[selecionado.selectedIndex].id;
        const medida = document.getElementById("unica").value
        const string = "Linfonodo proeminente à " + lado + hilo + textura + calcif + ", em nível " +
            nivel + ", medindo " + medida + " cm."
        setStrintToSpan(string)
    }
    const getDataTipicos = () => {
        let string = ""
        const fbilat = () => {
            const selecionadoD = document.getElementById(`select-linf-direita`);
            const nivelD = selecionadoD.options[selecionadoD.selectedIndex].id;
            const selecionadoE = document.getElementById(`select-linf-esquerda`);
            const nivelE = selecionadoE.options[selecionadoE.selectedIndex].id;
            const medidaD = document.getElementById("direita").value
            const medidaE = document.getElementById("esquerda").value
            string = "Linfonodos proeminentes com morfologia e textura habituais, os maiores em nível " +
                nivelD + " à direita, medindo " + medidaD + " cm, e nível " + nivelE + " à esquerda, medindo " + medidaE + " cm."
            setStrintToSpan(string)
        }
        const fDir = () => {
            const selecionadoD = document.getElementById(`select-linf-direita`);
            const nivelD = selecionadoD.options[selecionadoD.selectedIndex].id;

            const medidaD = document.getElementById("direita").value

            string = "Linfonodos proeminentes com morfologia e textura habituais à direita, os maiores em nível " +
                nivelD + ", medindo até " + medidaD + " cm."
            setStrintToSpan(string)
        }
        const fEsq = () => {
            const selecionadoD = document.getElementById(`select-linf-esquerda`);
            const nivelD = selecionadoD.options[selecionadoD.selectedIndex].id;

            const medidaD = document.getElementById("esquerda").value

            string = "Linfonodos proeminentes com morfologia e textura habituais à esquerda, os maiores em nível " +
                nivelD + ", medindo até " + medidaD + " cm."
            setStrintToSpan(string)
        }

        switch (lado) {
            case "bilateral":
                fbilat()
                break;
            case "direita":
                fDir()
                break;

            case "esquerda":
                fEsq()
                break;

            default:
                break;
        }

    }
    const getDataAtipicos = () => {
        let string = ""
        const hilo = document.querySelector(`input[name="radioLHilo"]:checked`).value
        const textura = document.querySelector(`input[name="radioLText"]:checked`).value
        const calcif = document.querySelector(`input[name="radioLCalc"]:checked`).value
        const fbilat = () => {
            const selecionadoD = document.getElementById(`select-linf-direita`);
            const nivelD = selecionadoD.options[selecionadoD.selectedIndex].id;
            const selecionadoE = document.getElementById(`select-linf-esquerda`);
            const nivelE = selecionadoE.options[selecionadoE.selectedIndex].id;
            const medidaD = document.getElementById("direita").value
            const medidaE = document.getElementById("esquerda").value
            string = "Linfonodos proeminentes bilaterais, "+hilo+textura+calcif+", os maiores em nível " +
                nivelD + " à direita, medindo " + medidaD + " cm, e nível " + nivelE + " à esquerda, medindo " + medidaE + " cm."
            setStrintToSpan(string)
        }
        const fDir = () => {
            const selecionadoD = document.getElementById(`select-linf-direita`);
            const nivelD = selecionadoD.options[selecionadoD.selectedIndex].id;

            const medidaD = document.getElementById("direita").value

            string = "Linfonodos proeminentes à direita, "+hilo+textura+calcif+", os maiores em nível " +
                nivelD + ", medindo até " + medidaD + " cm."
            setStrintToSpan(string)
        }
        const fEsq = () => {
            const selecionadoD = document.getElementById(`select-linf-esquerda`);
            const nivelD = selecionadoD.options[selecionadoD.selectedIndex].id;

            const medidaD = document.getElementById("esquerda").value

            string = "Linfonodos proeminentes à esquerda, "+hilo+textura+calcif+", os maiores em nível " +
                nivelD + ", medindo até " + medidaD + " cm."
            setStrintToSpan(string)
        }

        switch (lado) {
            case "bilateral":
                fbilat()
                break;
            case "direita":
                fDir()
                break;

            case "esquerda":
                fEsq()
                break;

            default:
                break;
        }

    }
    const nivel = (lado, acao) => {
        return (
            <select className="select-laudo-box" id={`select-linf-${lado}`} onChange={acao}>
                <option id="1A" value="lin_nivel_op1">1A - submentonianos</option>
                <option id="1B" value="lin_nivel_op2">1B - submandibulares</option>
                <option id="2" value="lin_nivel_op3">2A - base do crânio à borda inferior do osso hióide - Anterior</option>
                <option id="2B" value="lin_nivel_op4">2B - base do crânio à borda inferior do osso hióide - Posterior</option>
                <option id="3" value="lin_nivel_op5">3 -  inferior ao hióide até cricóide- anterior ao ECMT </option>
                <option id="4" value="lin_nivel_op6">4 -  inferior à cartil. cricóide até clavícula </option>
                <option id="5A" value="lin_nivel_op7">5A - posterior superior à cricóide</option>
                <option id="5B" value="lin_nivel_op8">5B - posterior inferior à cricóide</option>
                <option id="6" value="lin_nivel_op9">6 - central - do osso hióide ao manúbrio esternal</option>
                <option id="7" value="lin_nivel_op10">7 - abaixo do topo do manúbrio</option>
            </select>
        )
    }
    const blocoLabelMedida = (_label, _onBlur, _id) => {
        return (
            <div className="bloco-label-input-label">
                <label className="label-medida">{_label}</label>
                <input className="input-medida-maior" onBlur={_onBlur} type="text" id={_id} ></input>
                <label className="label-medida">cm</label>
            </div>
        )
    }
    const gerarTipico = () => {
        return (
            <div className="main-linf-tipico">
                <Stack direction="column" spacing={1} justifyContent="center" width="120px">
                    <Button label="normais" color="primary" onClick={() => { mudarBotoes("L2B1"); setLado("direita") }}
                        size="small" variant={buttonVariant.L2B1}>Direita</Button>
                    <Button label="normais" color="primary" onClick={() => { mudarBotoes("L2B2"); setLado("esquerda") }}
                        size="small" variant={buttonVariant.L2B2}>Esquerda</Button>
                    {/* <Button label="normais" color="primary" onClick={()=>mudarBotoes("L2B3")}
                    size="small"variant={buttonVariant.L2B3}>Bilateral</Button> */}
                </Stack>
                <div className="bloco-info">
                    {blocoLabelMedida("Medidas", getDataUnicoTipico, "unica")}
                    {nivel("unilateral", getDataUnicoTipico)}
                </div>
            </div>)
    }
    const gerarAtipico = () => {
        return (
            <div className="main-linf-tipico">
                <Stack direction="column" spacing={1} justifyContent="flex-start" width="120px">
                    <Button label="normais" color="primary" onClick={() => { mudarBotoes("L2B1"); setLado("direita") }}
                        size="small" variant={buttonVariant.L2B1}>Direita</Button>
                    <Button label="normais" color="primary" onClick={() => { mudarBotoes("L2B2"); setLado("esquerda") }}
                        size="small" variant={buttonVariant.L2B2}>Esquerda</Button>
                    {/* <Button label="normais" color="primary" onClick={()=>mudarBotoes("L2B3")}
                    size="small"variant={buttonVariant.L2B3}>Bilateral</Button> */}
                </Stack>
                <div className="bloco-info">
                    {blocoLabelMedida("Medidas", getDataUnicoAtipico, "unica")}
                    {nivel("unilateral", getDataUnicoAtipico)}
                    <p className="p-titulo-radios">Hilo</p>
                    <RadioButtons name={`radioLHilo`} opcoes={descritores.linfonodo.hilo} acao={getDataUnicoAtipico} />
                    <p className="p-titulo-radios">Textura</p>
                    <RadioButtons name={`radioLText`} opcoes={descritores.linfonodo.textura} acao={getDataUnicoAtipico} />
                    <p className="p-titulo-radios">Calcificações</p>
                    <RadioButtons name={`radioLCalc`} opcoes={descritores.linfonodo.calcif} acao={getDataUnicoAtipico} />
                </div>
            </div>)
    }
    const gerarAtipicos = () => {
        return (
            <div className="main-linf-tipico">
                <Stack direction="column" spacing={1} justifyContent="flex-start"  width="120px">
                    <Button label="normais" color="primary" onClick={() => { mudarBotoes("L2B1"); setLado("direita") }}
                        size="small" variant={buttonVariant.L2B1}>Direita</Button>
                    <Button label="normais" color="primary" onClick={() => { mudarBotoes("L2B2"); setLado("esquerda") }}
                        size="small" variant={buttonVariant.L2B2}>Esquerda</Button>
                    <Button label="normais" color="primary" onClick={() => { mudarBotoes("L2B3"); setLado("bilateral") }}
                        size="small" variant={buttonVariant.L2B3}>Bilateral</Button>
                </Stack>
                <div className="bloco-info">
                    {buttonVariant.L2B1 === "contained" && <div className="bloco-info">
                        {blocoLabelMedida("Medidas", getDataAtipicos, "direita")}
                        {nivel("direita", getDataAtipicos)}
                    </div>}
                    {buttonVariant.L2B2 === "contained" && <div className="bloco-info">
                        {blocoLabelMedida("Medidas", getDataAtipicos, "esquerda")}
                        {nivel("esquerda", getDataAtipicos)}
                    </div>}
                    {buttonVariant.L2B3 === "contained" && <div className="bloco-info">
                        {blocoLabelMedida("Medidas lado direito:", getDataAtipicos, "direita")}
                        {nivel("direita", getDataAtipicos)}
                        {blocoLabelMedida("Medidas lado esquerdo:", getDataAtipicos, "esquerda")}
                        {nivel("esquerda", getDataAtipicos)}
                    </div>}
                    <p className="p-titulo-radios">Hilo</p>
                    <RadioButtons name={`radioLHilo`} opcoes={descritores.linfonodo.hilo} acao={getDataAtipicos} />
                    <p className="p-titulo-radios">Textura</p>
                    <RadioButtons name={`radioLText`} opcoes={descritores.linfonodo.texturaPlural} acao={getDataAtipicos} />
                    <p className="p-titulo-radios">Calcificações</p>
                    <RadioButtons name={`radioLCalc`} opcoes={descritores.linfonodo.calcif} acao={getDataAtipicos} />
                </div>
            </div>)
    }
    const gerarTipicos = () => {
        return (
            <div className="main-linf-tipico">
                <Stack direction="column" spacing={1} justifyContent="center" width="120px">
                    <Button label="normais" color="primary" onClick={() => { mudarBotoes("L2B1"); setLado("direita") }}
                        size="small" variant={buttonVariant.L2B1}>Direita</Button>
                    <Button label="normais" color="primary" onClick={() => { mudarBotoes("L2B2"); setLado("esquerda") }}
                        size="small" variant={buttonVariant.L2B2}>Esquerda</Button>
                    <Button label="normais" color="primary" onClick={() => { mudarBotoes("L2B3"); setLado("bilateral") }}
                        size="small" variant={buttonVariant.L2B3}>Bilateral</Button>
                </Stack>
                {buttonVariant.L2B1 === "contained" && <div className="bloco-info">
                    {blocoLabelMedida("Medidas", getDataTipicos, "direita")}
                    {nivel("direita", getDataTipicos)}
                </div>}
                {buttonVariant.L2B2 === "contained" && <div className="bloco-info">
                    {blocoLabelMedida("Medidas", getDataTipicos, "esquerda")}
                    {nivel("esquerda", getDataTipicos)}
                </div>}
                {buttonVariant.L2B3 === "contained" && <div className="bloco-info">
                    {blocoLabelMedida("Medidas lado direito:", getDataTipicos, "direita")}
                    {nivel("direita", getDataTipicos)}
                    {blocoLabelMedida("Medidas lado esquerdo:", getDataTipicos, "esquerda")}
                    {nivel("esquerda", getDataTipicos)}
                </div>}
            </div>)
    }

    return (
        <div className='box-modelo'>
            <p className="box-modelo-titulo">Linfonodos</p>
            <Stack direction="row" spacing={1} justifyContent="center">
                <Button label="normal" color="primary" onClick={() => mudarBotoes("L1B1")}
                    size="small" variant={buttonVariant.L1B1}>Normais</Button>
                <Button label="tipico" color="primary" onClick={() => mudarBotoes("L1B2")}
                    size="small" variant={buttonVariant.L1B2}>Típico</Button>
                <Button label="atipico" color="primary" onClick={() => mudarBotoes("L1B3")}
                    size="small" variant={buttonVariant.L1B3}>Atípico</Button>
                <Button label="tipicos" color="primary" onClick={() => mudarBotoes("L1B4")}
                    size="small" variant={buttonVariant.L1B4}>Típicos</Button>
                <Button label="atipicos" color="primary" onClick={() => mudarBotoes("L1B5")}
                    size="small" variant={buttonVariant.L1B5}>Atípicos</Button>
            </Stack>
            {buttonVariant.L1B2 === "contained" && gerarTipico()}
            {buttonVariant.L1B3 === "contained" && gerarAtipico()}
            {buttonVariant.L1B4 === "contained" && gerarTipicos()}
            {buttonVariant.L1B5 === "contained" && gerarAtipicos()}

            <p className="box-result" /* onClick ={() => setMostraEscondeModal(true)} */>
                <span id={`spanId_${props.sufixo}`} >{props.modulo["1"]}</span>
            </p>
        </div>
    )
}
export default Linfonodos