import React, { useState,useEffect } from 'react'
import { useContext } from "react"
import MyContext from '../../../servicos/mycontext'
import Slider from '../../slider'
import './styles.css'


function Medidas3Vol (props) {
    const {atualizaSpans,setAtualizaSpans} = useContext(MyContext)

    const [med1,setMed1] =useState("")
    const [med2,setMed2] =useState("")
    const [med3,setMed3] =useState("")
    const [mostrar,setMostrar] = useState(true)

    const spanId = "spanId_" + props.sufixo
    const id1 = "med_1"+spanId
    const id2 = "med_2"+spanId
    const id3 = "med_3"+spanId


    useEffect(()=>{
        if(mostrar) {
            var volume = parseFloat(med1*med2*med3*0.523).toFixed(1)    
            props.acao(volume)
            var string = props.texto + med1.replace(".",",") +
            " x " + med2.replace(".",",") +
            " x " + med3.replace(".",",") +
            " cm. (Volume: " + volume.replace(".",",") +
            " cm³)"
            document.getElementById(spanId).innerHTML = string
            /* document.getElementById(`vol${spanId}`).value = volume
            document.getElementById(`vol${spanId}`).med2 = med2 */
            /* props.mudaExecCalcVol() */
        }
            setAtualizaSpans(!atualizaSpans)
    },[med1,med2,med3,mostrar]) // eslint-disable-line react-hooks/exhaustive-deps

    const mostrarEsconder = () =>{
        setMed1(""); setMed2(""); setMed3("")
        setMostrar(!mostrar)
        props.acao(0)
        setAtualizaSpans()
    }

    const onBlur = (id) => {
        const medida = document.getElementById(id).value;
        if (id === id1) setMed1(parseFloat(medida).toFixed(1));
        if (id === id2) setMed2(parseFloat(medida).toFixed(1));
        if (id === id3) setMed3(parseFloat(medida).toFixed(1));
    }

    return(
        <div className='box-modelo'>

            <p className='box-modelo-titulo'>{props.titulo}</p>
            <Slider checado={true} _id="3med1vol" acao={()=> mostrarEsconder()} mensagem ="Incluir esta medida?"/>
            
            {mostrar && <div>

            
            <div className="laudo-3x1" /* hidden={!mostrar} */>
            
            <label className="label-medida">Long:    </label>
            <input className="input-medida" onBlur={() => onBlur(`med_1${spanId}`)} type="number" id= {`med_1${spanId}`} ></input>
            <label className="label-medida">cm</label>
                    
            <label className="label-medida">AP:    </label>
            <input className="input-medida" onBlur={() => onBlur(`med_2${spanId}`)} type="number" id={`med_2${spanId}`} ></input>
            <label className="label-medida">cm</label>
        
            <label className="label-medida">Trans:    </label>
            <input className="input-medida" onBlur={() => onBlur(`med_3${spanId}`)} type="number" id={`med_3${spanId}`} ></input>
            <label className="label-medida">cm</label>

        </div>
        <p hidden={true} >
                <span className="input-medida" /* onBlur={() => onBlur(`vol${spanId}`)} */ type="number" id={`vol${spanId}`} ></span> cm.
            </p>
            <p className="box-result">
                <span id = {spanId}></span>
            </p>
            </div>}
        </div>
    )
}

export default Medidas3Vol