import React from 'react'

import "./styles.css"
/* import descritores from '../../../laudosUSG/tiroide/descritores.json' */
import MyContext from '../../../servicos/mycontext'
import { useContext } from "react"


function Titulo (props) {
    const {setAtualizaSpans} = useContext(MyContext)
    
    const acao = (item)=>{
        setAtualizaSpans()
        if(props.acao){
            props.acao(item)
        }
    }

    const descritores = props.descritores
    /* console.log("titulo") */
    const geraLista = () => {
        var lista = []
        const frases = Object.keys(descritores.titulo)
        var padrao
        frases.forEach(function(frase,item) {
            item === 0?padrao = true:padrao = false
            const element =
            <div key={`tituli-div${item}`}>
                <input onClick={()=>acao(item)}
                        key ={`titulo-input${item}`}
                        className="input-radio"
                        name = "titulo"
                        type="radio"
                        id={`titulo_op${item}`}
                        value={`titulo_op${item}`}
                        defaultChecked={padrao}/>
                <label  key ={`titulo-label${item}`}
                        htmlFor={`titulo_op${item}`}
                        className="label-radio"> {descritores.titulo[frase]}
                </label>
            </div>
            lista.push(element)
        })
        return lista
    }
    return (
        <div className="box-modelo">
            <p className="box-modelo-titulo">Título do exame</p>
            {geraLista()}
        </div>
    )
}
export default Titulo