import './styles.css'
import Slider from '../../slider'
import React, { useContext, useState } from 'react'
import MyContext from '../../../servicos/mycontext'
import { BotaoBrancoMenor } from '../../botoes'
import RadioButtons from '../../radio'




function Cabecalho (props) {

    const [showNome,setShowNome] = useState(false)
    const [showGenero,setShowGenero] = useState(false)
    const [showIdade,setShowIdade] = useState(false)
    /* const [showMedico,setShowMedico] = useState(true) */

    const {setAtualizaSpans,
        setGenero,
        setIdade,
        setModulo,
        setUltModulo,
        modulo
    } = useContext(MyContext)

    
    const acaoRadio =(event)=> {
        setGenero(event.target.value)
    }
    const acaoIdade = (event) =>{
        setIdade(document.getElementById("idade-paciente").value)
        console.log(document.getElementById("idade-paciente").value)
    }
    const teste = () => {
        setUltModulo(modulo)
        setModulo("")
    }
    const genero = {
        "op1": ["Masculino","masculino"],
        "op2": ["Feminino","feminino"]
    }
    
    

    return (
        <div className='box-modelo'>
            
            <p className="box-modelo-titulo">Informações Gerais</p>
            
            <Slider checado={false} mensagem="Inserir nome do paciente?"acao={() =>setShowNome(!showNome)}/>
            {showNome && <div>
                <input className="input-cabecalho"
                /* onFocus={() => onFocus()} */
                onBlur={setAtualizaSpans}
                type="text" id="nome-paciente"></input>
            </div>}
            
            <Slider checado={false} mensagem="Inserir gênero?"acao={() =>setShowGenero(!showGenero)}/>
            {showGenero && <RadioButtons
                opcoes={genero} name="radio-cabecalho"
                acao={acaoRadio}/>}
            
            
            <Slider checado={false} mensagem="Inserir idade?"acao={() =>setShowIdade(!showIdade)}/>
            {showIdade && <div>
                <input className="input-cabecalho-menor"
                onBlur={acaoIdade} placeholder="..em anos"
                type="number" min="0" step="1" id="idade-paciente"></input>
            </div>}
            <div className='cabecalho-container'>
            
            
            <Slider checado={true} _id="assin-sim" mensagem="Inserir nome do médico executor?"
            acao={setAtualizaSpans}/>
            <div style={{"width":"150px"}}>
                <BotaoBrancoMenor acao={teste}texto="Novo Laudo"/>
            </div>
            </div>

        </div>
    )
}

export default Cabecalho