import './styles.css'
import React from 'react'

function Slider (props) {

    const acao = () => {
        if (props.acao) props.acao()
    }

    return (
        
            <div className= "input-container">
                <span>{props.mensagem}</span>
                <label className="switch">
                <input  tabIndex="-1" defaultChecked={props.checado} className="input-checkbox" type="checkbox" onClick={acao} id={props._id}></input>
                <span className="slider round"></span>
                </label>
            </div>
        
    )
}

export default Slider