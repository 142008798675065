function ExportToDoc (nomeP) {
    /* const html = document.getElementById('previaLaudo').innerHTML */
    const postHtml = "</body></html>";
    const preHtml = `<!DOCTYPE html>
    <html>
        <head>
            <style>
                p.cabecalho{font-family: arial;font-size:14px;}
                h3 {font-size:16px; font-family: arial; text-align: center;margin-bottom: 28px;margin-top: 20px}    
                p.subtitulo{font-size:16px; font-family:arial; margin-bottom: 16px; margin-top: 16px;font-weight:700}
                p.pPadrao{font-size:16px;font-family: arial;}
                span.spanPadrao{font-size:16px;font-family: arial;font-weight:400}
                p.negrito{font-size:16px;font-family: arial;font-weight:700}
                p.negrito1{font-size:16px;font-family: arial;font-weight:700;display:inline}
                p.pItalico{font-size:14px; font-family:arial;font-style:italic; margin-bottom:16px; text-align:justify}
                p.pTabela{font-size:14px; font-family:arial;font-style:italic; margin-bottom:5px; text-align:left}
                th{font-family:arial;font-size: 12px}
                .rotulo-linha{text-align:left}
                p.topico-laudo{font-family: arial; font-size: 12px}
                p.rodape{text-align:right}
                                </style>
            <meta charset='utf-8'>
            <title>Laudo</title> 
        </head>
    <body>`
    var html = preHtml+document.getElementById('previaLaudo').innerHTML+postHtml;
    var blob = new Blob(['\ufeff', html], {
        type: 'application/msword'
    });
    // Specify link url
    var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
    console.log(html)
    
    // Specify file name
    /* filename = filename?filename+'.doc':'document.doc'; */
    const currentdate = new Date();
    // define nome do paciente
    var nomePaciente
    const index = nomeP.indexOf(" ")
    index === -1? nomePaciente = nomeP:nomePaciente = nomeP.slice(0,index) + "_"

    var filename = "laudo_" + nomePaciente + currentdate.getFullYear()
        + (currentdate.getMonth()+1)  
        + currentdate.getDate() + "_"
        + currentdate.getHours() + "h"  
        + currentdate.getMinutes() + "m.doc" 
    
    // Create download link element
    var downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    
    if(navigator.msSaveOrOpenBlob ){
        navigator.msSaveOrOpenBlob(blob, filename);
    }else{
        // Create a link to the file
        downloadLink.href = url;
        
        // Setting the file name
        downloadLink.download = filename;
        
        //triggering the function
        downloadLink.click();
    }
    
    document.body.removeChild(downloadLink);
}
export default ExportToDoc