import React, { useContext, useEffect, useState } from "react"
import Slider from "../../componentes/slider"
import MyContext from '../../servicos/mycontext'
import descritores from './descritores.json'

function VGT (props) {
    
    const [somarIstmo,setSomarIstmo]= useState(true)
    const [lerSpans,setLerSpans] =useState(false)
    const {setAtualizaSpans,genero,idade} = useContext(MyContext)
    const [normal,setNormal] =useState()
    const [mostrar,setMostrar] = useState(true)

    
    var spanId = "spanId_" + props.sufixo
    
    useEffect(() =>{
        setAtualizaSpans()
      
    },[lerSpans])

     useEffect (()=> {
        if(!mostrar) return
        var volume 
        if(somarIstmo){
            volume = (parseFloat(props.volD) + parseFloat(props.volE) + parseFloat(props.volI)).toFixed(1)
        }else {
            volume = (parseFloat(props.volD) + parseFloat(props.volE)).toFixed(1)
        }
        if (volume > 0.0) {
            const string1 =("Volume glandular total: " + volume.replace(".",",") + " cm³ (" + normal + ")")
            document.getElementById(spanId).innerHTML = string1
        } else {
            document.getElementById(spanId).innerHTML = ""
        }
        setLerSpans(!lerSpans)
    },[props.volD,props.volE,props.volI,somarIstmo,normal])
     
    useEffect (() =>{
        setNormal(criaNormal())
    },[idade,genero])

    const criaNormal = () =>{
        const grupo = descritores.volume[genero]
        var resposta
        if (idade > 14){
            return(grupo.k[1])
        }
        if (idade < 6){
            return(grupo.a[1])
        }
        const idades = Object.keys(grupo)
        idades.forEach((item) =>{
            if (grupo[item][0] == idade) resposta = grupo[item][1] 
        })

        return resposta
    }

    const acaoSlider =()=> {
        setSomarIstmo(!somarIstmo)
    }
    const mostrarEsconder=()=>{
        setMostrar(!mostrar)
        setAtualizaSpans()
    }

    return(
        <div className='box-modelo'>
            <p className='box-modelo-titulo'>VGT</p>
            <Slider checado={true} acao={acaoSlider} mensagem="Incluir Istmo no volume?"/>
            <Slider checado={true} acao={mostrarEsconder} mensagem="Incluir volume no laudo?"/>
            {mostrar && <p className="box-result">
               <span className="input-sozinho" id= {spanId}></span>
            </p>}
        </div>

    )
}
export default VGT