import React, { useContext, useEffect, useState } from 'react'
import MyContext from '../../servicos/mycontext'
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import './styles.css'
/* import { modalClasses } from '@mui/material'; */
import api from '../../servicos/api';


function Usuario () {
    
    const [modo,setModo] = useState({
        nome:"view",
        username:"view",
        crm:"view",
        senha:"view"
    })
    const [newUser,setNewUser] = useState({
        nome:"",
        username:"",
        crm:"",
        senha:""
    })
    const [alertView,setAlertView] =useState({
        username:false,
        sucesso:false
    })
    const {user,setUser} = useContext(MyContext)
    
    useEffect(()=>{
        setNewUser({
            nome:user[1],
            crm:user[3],
            senha:user[2],
            username:user[0]
        })
    },[])
    
    const goToEdit =(param)=>{
        const newModo = {...modo,[param]:"edit"}
        setModo(newModo)
    }
    const cancelar = (param)=>{
        const newModo = {...modo,[param]:"view"}
        setModo(newModo)
    }
    const salvar = async (param,id)=> {
        const item = document.getElementById(id).value
        var response
        try {
            response = await api.put('/user/update',{[param]:item})
        } catch (error) {
            console.log(error)
        }
        if (response.data.resposta==="Já existe"){
            setAlertView({sucesso:false,username:true})
        }
        if (response.data.resposta==="Alteração efetuada"){

            setAlertView({username:false,sucesso:true})
        }
        const newObject= {...newUser,[param]:item}
        setUser([newObject.username,newObject.nome,'',newObject.crm])
        setNewUser(newObject)
        cancelar(param)
    }
    
    const closeAlert = (param)=> {
        setAlertView({...alertView,[param]:false})
    }

    return (
    <div className='box-modelo'>
        <p className='box-modelo-titulo'>Informações pessoais</p>
        <div className='container-info'>
            <span className='info-titulo'>Nome</span>
            <input
            className={modo.nome==="view"?'info-input':'info-input-dis'} id="info-nome" /* placeholder={newUser.nome} */
            disabled={modo.nome==="view"?true:false} defaultValue={newUser.nome}></input>
            {modo.nome==="view"?
            <EditIcon className="icons-info" style={{ color: "var(--laranja)"}} onClick={()=>goToEdit("nome")}/>:
            <DoneIcon className="icons-info" style={{ color: "blue" }} onClick={()=>salvar("nome","info-nome")}/>}
            {modo.nome==="edit"?
            <CloseIcon className="icons-info" style={{ color: "red"}}onClick={()=>cancelar("nome")}/>:null}
        </div>
        <div className='container-info'>
            <span className='info-titulo'>Username</span>
            <input
            className={modo.username==="view"?'info-input':'info-input-dis'} id="info-username" /* placeholder={newUser.username} */
            disabled={modo.username==="view"?true:false} defaultValue={newUser.username}></input>
            {modo.username==="view"?
            <EditIcon className="icons-info" style={{ color: "var(--laranja)"}} onClick={()=>goToEdit("username")}/>:
            <DoneIcon className="icons-info" style={{ color: "blue" }} onClick={()=>salvar("username","info-username")}/>}
            {modo.username==="edit"?
            <CloseIcon className="icons-info" style={{ color: "red"}}onClick={()=>cancelar("username")}/>:null}
        </div>
        <div className='container-info'>
            <span className='info-titulo'>CRM</span>
            <input
            className={modo.crm==="view"?'info-input':'info-input-dis'} id="info-crm" /* placeholder={newUser.crm} */
            disabled={modo.crm==="view"?true:false} defaultValue={newUser.crm}></input>
            {modo.crm==="view"?
            <EditIcon className="icons-info" style={{ color: "var(--laranja)"}} onClick={()=>goToEdit("crm")}/>:
            <DoneIcon className="icons-info" style={{ color: "blue" }} onClick={()=>salvar("crm","info-crm")}/>}
            {modo.crm==="edit"?
            <CloseIcon className="icons-info" style={{ color: "red"}}onClick={()=>cancelar("crm")}/>:null}
        </div>
        {alertView.username?<Alert onClose={() => closeAlert("username")} severity="warning">Alteração não efetuada - Username não diponivel</Alert>:null}
        {alertView.sucesso?<Alert onClose={() => closeAlert("sucesso")} severity="success">Alteração efetuada com sucesso!</Alert>:null}
        
    </div>
    )
}

export default Usuario