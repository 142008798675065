import React from 'react'
import Login from './paginas/login'
import './App.css'
import Principal from './paginas/principal';
import { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#da552f',
    },
    secondary: {
      main: '#F8F6F6',
      contrastText:"#da552f"
    },
  },
  typography: {
   
    button:{

      fontSize:20,
      letterSpacing: 1.5,
      fontWeight:600,
      textTransform:'none'
    },
   
    
  },
});


function App() {
  
  
  const [usuarioLogado,setUsuarioLogado] = useState([])

  const dev =false

  return (
    <div>
       <ThemeProvider theme={theme}>

      {(usuarioLogado.length===0?
        <Login setUser={setUsuarioLogado} dev={dev}/>:
        <Principal setUser={setUsuarioLogado} user={usuarioLogado}/>
        )}
      </ThemeProvider>
    </div>
    
  );
}

export default App;
