
///recebe como props: "tipo caixaSelecao"
//import { AiFillPropertySafety } from 'react-icons/ai'
/* import React, { useState } from 'react' */
/* import {AiFillEdit} from "react-icons/ai"; */

import React from 'react'
import { useContext } from "react"
import MyContext from '../../../servicos/mycontext'
import './styles.css'
/* import ModalEditar from '../../../ModalEditar' */

function Select (props) {
    const {atualizaSpans,setAtualizaSpans} = useContext(MyContext)
    /* const [mostraEscondeModal, setMostraEscondeModal] =useState(false) */
        
    var spanId = "spanId_" + props.sufixo
    
    
    const renderLista = () => {
        const keys =Object.keys(props.modulo)
        var lista=[]
        var padrao
        keys.forEach((key,index) => {
            key === "1"? padrao=true:padrao=false
            const element =
                <option 
                    key={`key-option${index}`}
                    value ={key}    
                    /* value={`desc_frase1_op${index}`} */
                    defaultChecked={padrao}
                    >{props.modulo[key]}</option>
            if(key!=="nome") lista.push(element)
        })
        return lista
    }

    const onSelect = (sufixo) => {
        const selecionado = document.getElementById(sufixo);
        const texto = selecionado.options[selecionado.selectedIndex].text;
        document.getElementById(spanId).innerHTML = texto;
        setAtualizaSpans(!atualizaSpans)
/*         if (props.onSelect !== undefined) {
            props.onSelect(selecionado.options[selecionado.selectedIndex].value,sufixo)
        } */
    }
    

return ( 

    <div className='box-modelo'>
       {/*  {mostraEscondeModal && <ModalEditar span={spanId}
                mostraEscondeModal = {setMostraEscondeModal}
                lerSpans = {props.switchLerSpans}/>} */}
        <p className="box-modelo-titulo">{`Selecione - ${props.modulo.nome}`}</p>
            <div className="div-select">
            <select className="select-laudo-box" id={props.sufixo} onChange={() => onSelect(props.sufixo)}>
                {renderLista()}
            </select>
            {/* <button className="button-select" onClick={() => props.sentprops.mostraEscondeModalPersSelect(true,props.selectId)}><AiFillEdit/></button> */}
            </div>
                {/* <p className="box-result" onClick ={() => props.sentprops.mostraEscondeModal(true,spanId, props.switchLerSpans)}> */}
                <p className="box-result" /* onClick ={() => setMostraEscondeModal(true)} */>
                    <span id = {spanId} title={props.modulo.nome} >{props.modulo["1"]}</span> 
                </p>
    
{/*             <div className="tooltip">
                <span className="tooltip-texto"> Clique para editar</span>
            
                <p className="box-result" onClick ={() => setMostraEscondeModal(true)}>
                    <span id = {spanId} title={props.modulo.nome} >{props.modulo.frase1}</span> 
                </p>
            </div> */}
    </div>
)
}
export default Select