import React, { useContext, useEffect, useState } from 'react'
import MyContext from '../../servicos/mycontext'
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Alert from '@mui/material/Alert';
import './styles.css';
import api from '../../servicos/api';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
//import CloseIcon from '@mui/icons-material/Close';
//import DoneIcon from '@mui/icons-material/Done';
//import Badge from '@mui/material/Badge';

import {laudoView} from "./laudoView"



function Casos () {
    
    const {setLaudoView} = useContext(MyContext)
    const [modoDeletar,setModoDeletar] = useState(false)
    const [dados,setDados] = useState([])
    const [dadosFiltrados,setDadosFiltrados] = useState([])
    const [pagina,setPagina] = useState(1)
    const [numPag,setNumPag] = useState(1)
    const [tags,setTags] = useState([])
    const [boxVariavel,setBoxVariavel] = useState("")
    const [verFiltros,setVerFiltros] =useState(false)
    const [alertView,setAlertView] = useState([false,""])
    const [valoresPadrao,setValoresPadrao] = useState({titulo:"",ficha:"",texto:"",id:""})
    
    const itensPorPagina = 5
 
    useEffect(()=>{
        getDataFromDB()
        setLaudoView(laudoView)        
    },[])
    
    useEffect(()=>{
        filtrar()      
    },[tags])
    
    const getDataFromDB = async () =>{
        const response =  await api.get('/casos/buscartodos')
        setDados(response.data)
        setNumPag(Math.ceil(response.data.length/itensPorPagina))
    }

    const listarNovaTags =() =>{
        const handleDelete =(param)=>{
            const newArray = [...tags]
            newArray.splice(param,1)
            setTags(newArray)
        }
        if (tags.length===0) return []
        var lista =[]
        tags.forEach((element,index)=>{
            const item = 
                <Chip key={`key_chip_${index}`}
                    sx={{color:"var(--laranja)",borderColor:"var(--laranja)",'& .MuiChip-deleteIcon': {color: 'var(--laranja)'}}}
                    variant = "outlined"label= {element}
                    onDelete={() => handleDelete(index)}
                />
            lista.push(item)
        })
        return  <Stack direction='row' justifyContent="flex-start" spacing={2} alignItems="center">
                   {lista}
                </Stack>
    }
    const renderTags = () => {
        const salvarTag =()=>{
            const tag = document.getElementById("autoComp-tags").value
            if (tag !=="") {
                const newArray = [...tags]
                newArray.push(tag)
                newArray.sort()
                setTags(newArray)
            }
        }
        var lista =[]
        dados.forEach(element =>{
            lista = [...lista,...element.tags]
        })
        lista.sort()
        const newSet = [...new Set(lista)]
              
        return <div>
            <Stack direction='row' justifyContent="flex-center" alignItems="center" spacing={2}>
                <Autocomplete
                    disablePortal id="autoComp-tags" freeSolo                
                    selectOnFocus options={newSet} sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Adicionar tags" size='small' />}
                    />
                <AddCircleIcon className="icons-casos" style={{ color: "var(--laranja)" }} onClick={()=>salvarTag()}/>
            </Stack>
            </div>
    }

    const renderLista = () =>{
        var dadosUsados =[...dados]
        if (verFiltros) dadosUsados =[...dadosFiltrados]

        var lista = []
        dadosUsados.forEach((element,index)=>{
            if (index >= itensPorPagina*pagina || index < itensPorPagina*pagina-itensPorPagina) return
            var listaTags = []
            const date = `${element.createdAt.slice(8,10)}/${element.createdAt.slice(5,7)}/${element.createdAt.slice(0,4)}`
            element.tags.forEach ((tag,i) =>{
                const subitem = <Chip sx={{color:"var(--laranja)",borderColor:"var(--laranja)"}}
                    variant='outlined' key ={`key_subitem_${i}`} label = {tag}/>
                listaTags.push(subitem)
            })            
            const item = <div className='box-modelo' key={`key_c_${index}`}>
                <Stack direction="row" justifyContent="space-between" sx={{marginTop:"5px"}}>
                    <p className='"box-modelo-titulo'>{element.titulo}</p>
                    {modoDeletar === element._id?
                        <Stack direction="row" justifyContent="space-around" alignItems="center" spacing={1} sx={{paddingRight:"10px"}}>
                            <p className='"box-modelo-titulo'>Excluir?</p>
                                <Chip label="Não" sx={{color:"var(--laranja)",borderColor:"var(--laranja)"}}
                                    onClick={()=>{setModoDeletar(false)}} variant="outlined"/>
                                <Chip label="Sim" sx={{color:"var(--laranja)",borderColor:"var(--laranja)"}}
                                    onClick={()=>{deletarItem(element._id)}} variant="outlined"/>
                        </Stack>
                        :
                        <Stack direction="row" justifyContent="space-around" spacing={1} sx={{paddingRight:"10px",marginBottom:"8px"}}>
                            <EditIcon className="icons-casos" style={{ color: "var(--laranja)"}} onClick={()=>goToEdit(element)}/>
                            <DeleteForeverIcon className="icons-casos" style={{ color: "var(--laranja)"}} onClick={()=>setModoDeletar(element._id)}/>
                        </Stack>}
                </Stack>

                    <label className="label-medida">Data:</label>
                    <Chip variant = "outlined" label= {date}/>
                    <label className="label-medida">Ficha:</label>
                    <Chip variant = "outlined" label= {element.ficha}/>
                    <p></p>
                    <label className="label-medida">Tags:</label>
                    <Stack direction="row" spacing={1} sx ={{marginTop:"5px"}}>
                        {listaTags}
                    </Stack>
                    <TextField
                        sx = {{marginTop:"15px",width:"100%"}} id="outlined-multiline-static"
                        label="Observações" multiline//rows={4}
                        defaultValue={element.texto}/>

            </div>
            lista.push(item)
        })
        return lista
    }
    const handleChange = (event, value) =>{
        setPagina(value)
    }
    const salvarCaso = async ()=>{
        // salvao caso novo ou editado, dependendo do ID se encontrar no state - valoresPadrao
        const titulo = document.getElementById("titulo").value
        const ficha = document.getElementById("ficha").value
        const texto = document.getElementById("texto").value
        if (titulo === "" || ficha ==="" || texto ===""){
            setAlertView([true,"Os campos título, Ficha e Observações são obrigatórios"])
        } else if (valoresPadrao.id === "") {
            try {
                await api.post("/casos/criarCaso",{titulo,texto,tags,ficha})
                document.getElementById("titulo").value = ""
                document.getElementById("ficha").value = ""
                document.getElementById("texto").value = ""
                setTags([])
                getDataFromDB()
                setAlertView([true,"Item adicionado com sucesso"])
            } catch (error) {
                setAlertView([true,"Houve algo de errado...desculpe"])
            }
        } else {
            try {
                await api.post('/casos/update',{titulo,texto,tags,ficha,id:valoresPadrao.id})
                getDataFromDB()
                setAlertView([true,"Item atualizado com sucesso"])
            
            } catch (error) {
                setAlertView([true,"Houve algo de errado...desculpe"])
            }
            
        }

    }
    const cancelar =()=>{
        document.getElementById("titulo").value = ""
        document.getElementById("ficha").value = ""
        document.getElementById("texto").value = ""
        setTags([])
        setValoresPadrao({titulo:"",ficha:"",texto:"",id:""})
        setBoxVariavel("")
    }
    const deletarItem = async (param) => {
        console.log(param)
        try {
            await api.post('/casos/apagar',{id:param})
            setModoDeletar(false)
            //setAtualizaDados(!atualizaDados)
            getDataFromDB()
            filtrar()
            setAlertView([true,"Item apagado com sucesso"])
        } catch (error) {
            setAlertView([true,"Houve algo de errado...desculpe"])
        }
    }
    const goToEdit = (element) =>{
        var newObj = {
            titulo:element.titulo,
            ficha:element.ficha,
            texto:element.texto,
            id:element._id}
        setTags(element.tags)
        setValoresPadrao(newObj)
        setBoxVariavel("novoCaso")

    }
    const filtrar = () =>{
        var lista =[]
        dados.forEach(element=>{
            var status = true
            tags.forEach(tag=>{
                if(element.tags.indexOf(tag)<0) status = false
            })
            if (status) lista.push(element)
        })
        
        //filtro ficha
        var ficha = ""
        if(document.getElementById('fichafiltro')) ficha = document.getElementById('fichafiltro').value
        if (ficha.length>2){
            var listaMenor = []
            lista.forEach(element =>{
                if(element.ficha.includes(ficha)) listaMenor.push(element)
            })
            lista =[...listaMenor]
        }
        setDadosFiltrados(lista)
    }
    const alternarFiltro =()=>{
        setVerFiltros(!verFiltros)
        setTags([])
    }
    return (
        <div>
            <div className='box-modelo'>
                <Stack direction="row" justifyContent="space-between" spacing={3}>
                    <Button variant="contained" size="small" disabled={boxVariavel==="novoCaso"?true:false}
                        onClick={() =>setBoxVariavel("novoCaso")}
                        color="primary">Adicionar Caso</Button>
                    <Button variant="contained" size="small" disabled={boxVariavel==="novoCaso"?true:false}
                        onClick={() =>alternarFiltro()}
                         color="primary">{verFiltros?"Remover Filtros":"Filtrar"}</Button>
                    <Button variant="contained" size="small" disabled={boxVariavel===""?true:false}
                        onClick={() =>setBoxVariavel("")}
                         color="primary">Listar Tudo</Button>
                </Stack>
            </div>

            {alertView[0]?<Alert sx={{marginBottom:"10px"}} onClose={() => setAlertView([false,""])}
                    severity="warning">{alertView[1]}</Alert>:null}
            
            {boxVariavel==="novoCaso"?<div className='box-modelo'>
            <Stack spacing={2} sx={{paddingTop:"5px"}}>
                <TextField sx={{width:"220px"}} size="small" id="titulo"
                        defaultValue={valoresPadrao.titulo}
                        label="Título"  variant="outlined" required/>
                <TextField sx={{width:"220px"}} size="small" id="ficha"
                        defaultValue={valoresPadrao.ficha}
                        label="Ficha" variant="outlined" required/>
                {renderTags()}
                {listarNovaTags()}
                <TextField multiline size="small" id="texto"
                    defaultValue={valoresPadrao.texto}
                    label="Observações" variant="outlined" required/>
                <Stack direction="row" spacing={5} justifyContent='space-between'>
                    <Button variant="contained" size="small" onClick={salvarCaso}
                        color="primary">Salvar
                    </Button>
                    <Button variant="contained" size="small" onClick={cancelar}
                        color="primary">Voltar
                    </Button>
                </Stack>
            </Stack>
            </div>:null}

            {verFiltros?<div className='box-modelo'>
            <p className='"box-modelo-titulo'>Filtros</p>
                <Stack direction='row' spacing={3} justifyContent="flex-start" alignItems="center" sx={{marginBottom:"10px"}}>
                    <label className="label-medida">Tags:</label>
                    {renderTags()}
                </Stack>
                {listarNovaTags()}
                <Stack direction='row' spacing={3} justifyContent="flex-start" alignItems="center" sx={{marginBottom:"10px"}}>
                    <label className="label-medida">Ficha:</label>
                    <TextField size='small' id="fichafiltro" label="Ficha" variant='outlined' onKeyUp={filtrar}/>
                </Stack>

                
            </div>:null}
            
            {boxVariavel!==""?null:
                <div>
                    {dados.length >0?<div>{renderLista()}</div>:<LinearProgress/>}
                    <Stack sx={{backgroundColor:"#F8F6F6",borderRadius:"5px"}} spacing={2}>
                    <Pagination count={numPag} page={pagina} onChange={handleChange} />
                    </Stack>
            </div>}
                
        </div>
    )
}
export default Casos