import React, { useContext, useEffect, useState } from "react";
import descritores from './descritores.json'
import RadioButtons from "../../componentes/radio";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import MyContext from "../../servicos/mycontext";


function Salivares(props) {
    const [ladoSub,setLadoSub]=useState("bilateral")
    const [ladoPar,setLadoPar]=useState("bilateral")

    const{setAtualizaSpans} = useContext(MyContext)

    useEffect(() =>{
        criarFrase(ladoSub,"sub")
    },[ladoSub])
    useEffect(() =>{
        criarFrase(ladoPar,"par")
    },[ladoPar])

    const mudaLadoSub=(event,valor) =>{
        if(valor) setLadoSub(valor)
    }
    const mudaLadoPar=(event,valor) =>{
        if (valor) setLadoPar(valor)
    }

    const criarFrase = (lado,nome) =>{
        const texto= descritores.glandulas.texto
        const dimensoes= document.querySelector(`input[name="radio_gs_d_${nome}"]:checked`).value
        const textura= document.querySelector(`input[name="radio_gs_t_${nome}"]:checked`).value
        const doppler= document.querySelector(`input[name="radio_gs_f_${nome}"]:checked`).value
        var ladoNormal = ""
        if (lado==="esquerda") ladoNormal="direita"
        if (lado==="direita") ladoNormal="esquerda"
        var string2 = ` Glândula ${texto[nome][lado]} ${ladoNormal} com dimensões e ecogenicidade normais, sem sinais de dilatação ductal.`
        if(lado==="bilateral"){
            string2 = ""
        }
        const string = texto[lado][0] + texto[nome][lado] +
        texto[lado][1] + dimensoes + textura + texto[lado][2] + doppler
        
        document.getElementById(`spanId_${props.sufixo}_${nome}`).innerHTML=string
        document.getElementById(`spanId_${props.sufixo2}_${nome}`).innerHTML=string2
        setAtualizaSpans()
    }
    return (
        <div>
        
        <div className="box-modelo">
        <p className="box-modelo-titulo">Glândulas Submandibulares</p>
        <div className="div-group-container">
            <ToggleButtonGroup
                color="primary" exclusive
                value={ladoSub} onChange={mudaLadoSub} aria-label="Platform">
                <ToggleButton value="bilateral">Bilateral</ToggleButton>
                <ToggleButton value="esquerda">Esquerda</ToggleButton>
                <ToggleButton value="direita">Direita</ToggleButton>
            </ToggleButtonGroup>
        </div>
        <p className="p-titulo-radios">Dimensões</p>
            <RadioButtons opcoes={descritores.glandulas.dimensoes} name={`radio_gs_d_sub`}
            acao={() => criarFrase(ladoSub,"sub")}/>
        <p className="p-titulo-radios">Ecogenicidade / textura</p>
            <RadioButtons opcoes={descritores.glandulas.textura} name={`radio_gs_t_sub`}
            acao={() => criarFrase(ladoSub,"sub")}/>
        <p className="p-titulo-radios">Doppler</p>
            <RadioButtons opcoes={descritores.glandulas.doppler} name={`radio_gs_f_sub`}
            acao={() => criarFrase(ladoSub,"sub")}/>
        <p className="box-result" /* onClick ={() => setMostraEscondeModal(true)} */>
            <span id = {`spanId_${props.sufixo}_sub`} /* title={props.modulo.nome} */ ></span> 
        </p>
        <p className="box-result" /* onClick ={() => setMostraEscondeModal(true)} */>
            <span id = {`spanId_${props.sufixo2}_sub`} /* title={props.modulo.nome} */ ></span> 
        </p>
        </div>
        
        <div className="box-modelo">
        <p className="box-modelo-titulo">Glândulas Parótidas</p>
        <div className="div-group-container">
            <ToggleButtonGroup
                color="primary" exclusive
                value={ladoPar} onChange={mudaLadoPar} aria-label="Platform">
                <ToggleButton value="bilateral">Bilateral</ToggleButton>
                <ToggleButton value="esquerda">Esquerda</ToggleButton>
                <ToggleButton value="direita">Direita</ToggleButton>
            </ToggleButtonGroup>
        </div>
        <p className="p-titulo-radios">Dimensões</p>
            <RadioButtons opcoes={descritores.glandulas.dimensoes} name={`radio_gs_d_par`}
            acao={() => criarFrase(ladoPar,"par")}/>
        <p className="p-titulo-radios">Ecogenicidade / textura</p>
            <RadioButtons opcoes={descritores.glandulas.textura} name={`radio_gs_t_par`}
            acao={() => criarFrase(ladoPar,"par")}/>
        <p className="p-titulo-radios">Doppler</p>
            <RadioButtons opcoes={descritores.glandulas.doppler} name={`radio_gs_f_par`}
            acao={() => criarFrase(ladoPar,"par")}/>
        <p className="box-result" /* onClick ={() => setMostraEscondeModal(true)} */>
            <span id = {`spanId_${props.sufixo}_par`} /* title={props.modulo.nome} */ ></span> 
        </p>
        <p className="box-result" /* onClick ={() => setMostraEscondeModal(true)} */>
            <span id = {`spanId_${props.sufixo2}_par`} /* title={props.modulo.nome} */ ></span> 
        </p>
        </div>
        </div>
    )
}

export default Salivares