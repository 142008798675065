import './styles.css'
import './laudoview.css'
import {BotaoSimplesBranco, BotaoBrancoMenor } from '../../componentes/botoes'
import {useEffect, useState} from 'react'
import modalidades from '../../servicos/modalidades.json'
import Tiroide from '../../laudosUSG/tiroide'
import Mamas from '../../laudosUSG/mamas'
import MyContext from '../../servicos/mycontext'

import React from 'react'
/* import ChecarSessao from '../../servicos/checarSessao' */
import Usuario from '../../componentes/usuario'
import Casos from '../../componentes/casos'
import api from '../../servicos/api'

function Principal (props) {

    const [modalidade,setModalidade] =useState ("usg")
    const [laudoView,setLaudoView] = useState([])
    const [atualizaSpans,setAtual] = useState(false)
    const [genero,setGenero] = useState("masculino")
    const [idade,setIdade] = useState(30)
    const [modulo,setModulo] =useState("tiroide")
    const [ultModulo,setUltModulo] =useState("")
    
    const setAtualizaSpans = () =>{
        setAtual(!atualizaSpans)
    }
    // novo laudo
    useEffect(()=>{
        if(modulo==="") setModulo(ultModulo)
        setIdade(30)
        setLaudoView([])
    },[modulo])
    
    useEffect(()=>{
        if(modalidade==="pessoal") setModulo("vazio")
        if(modalidade==="usg")  setModulo("tiroide")
    },[modalidade])

    const renderezidaBotoes = () => {
        var lista = []
        modalidades[modalidade].forEach(exame => {
            const item = (<BotaoBrancoMenor key={`bbm${exame}`} acao={()=>setModulo(exame)} texto={exame}/>)
            lista.push(item)
        })
        return lista
    }
    const sair = async ()=>{
        try {
            const response = await api.get('/user/logout')
            if(response){
                props.setUser([])
            }
            console.log(response.data)
            
        } catch (error) {
            console.log(error)            
        }
        

    }
   
    return (
        <MyContext.Provider
        value={
            {laudoView,setLaudoView,
            atualizaSpans,setAtualizaSpans,
            genero,setGenero,
            idade,setIdade,
            modulo,setModulo,
            setUltModulo,
            user:props.user,
            setUser:props.setUser}
        }>
        <div className='principal-main-container'>
            <div className='principal-barra-superior'>
                <p>{props.user[1]}</p>
                <div className='botoes-container'>
                    <BotaoSimplesBranco acao={() => setModalidade("usg")} texto="USG"/>
                    <BotaoSimplesBranco acao={() => setModalidade("tc")} texto="TC"/>
                    <BotaoSimplesBranco acao={() => setModalidade("rm")} texto="RM"/>
                </div>
                <BotaoSimplesBranco acao={() => setModalidade("pessoal")} texto="Extras"/>
                <BotaoSimplesBranco acao={sair}texto="Sair"/>
            </div>
            <div className='principal-corpo'>
                <div className='corpo-barra-lateral'>
                    {renderezidaBotoes()}
                </div>
                <div className='corpo-modulo'>
                    {modulo===""?undefined:null}
                    {modulo==="vazio"?<div></div>:null}
                    {modulo==="tiroide"?<Tiroide/>:null}
                    {modulo==="mamas"?<Mamas/>:null}
                    {modulo==="Info"?<Usuario/>:null}
                    {modulo==="Seguir Casos"?<Casos/>:null}
                </div>
                <div className='corpo-laudo-view'>
                    <div className='corpo-laudo-box'>

                    {laudoView}
                    </div>
                </div>
            </div>
        </div>
        </MyContext.Provider>

    )
}

            
export default Principal